<template>
  <div class="h-screen w-full bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 animate-gradient-x">
    <div class="flex items-center justify-center h-full">
      <h1 class="text-5xl text-white font-bold">Animated Gradient Background</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnimatedGradient',
};
</script>

<style scoped>
@keyframes gradient-x {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.animate-gradient-x {
  animation: gradient-x 8s ease infinite;
  background-size: 200% 200%;
}
</style>
