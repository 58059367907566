export default function bubblingInvertPush(el, _options = {}) {
  const canvas = el.tagName === 'CANVAS' ? el : document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    

  if (el.tagName !== 'CANVAS') {
    const { width, height } = el.getBoundingClientRect();
    canvas.width = width;
    canvas.height = height;

    el.style.position = 'relative';
    canvas.style.position = 'absolute';
    canvas.style.top = 0;
    canvas.style.left = 0;
    canvas.style.pointerEvents = 'none';

    el.appendChild(canvas);
  }

  let time = 0;

  function draw() {
    //const w = canvas.width;
      // const h = canvas.height;
    const target = el.querySelector('img') || el;
    const { width, height } = target.getBoundingClientRect();
    canvas.width = width;
    canvas.height = height;

    const w = canvas.width;
    const h = canvas.height;
    const pulse = Math.sin(time * 0.05);

    ctx.clearRect(0, 0, w, h);
    //console.log('Canvas size:', canvas.width, canvas.height);

    const blobCount = 10;
    for (let i = 0; i < blobCount; i++) {
      const x = (w / blobCount) * i + Math.sin(time * 0.02 + i) * 10;
      const y = h / 2 + Math.sin(time * 0.03 + i * 2) * 20;

      const radius = 20 + Math.sin(time * 0.05 + i) * 10;
      ctx.beginPath();
      ctx.arc(x, y, Math.abs(radius), 0, Math.PI * 2);
      ctx.fillStyle = pulse > 0 ? 'white' : 'black';
      ctx.fill();
    }

    time++;
    requestAnimationFrame(draw);
  }

  draw();
}
