<template>
  <div>
    <h2>Add a New Blog Post</h2>
    <form @submit.prevent="submitPost">
      <div>
        <label for="title">Title:</label>
        <input type="text" id="title" v-model="title" required />
      </div>
      <div>
        <label for="content">Content:</label>
        <textarea id="content" v-model="content" required></textarea>
      </div>
      <button type="submit">Add Post</button>
    </form>
  </div>
</template>

<script>
import { addBlogPost } from '../../services/api.js'; // Importing the addBlogPost function from api.js

export default {
  data() {
    return {
      title: '',
      content: ''
    };
  },
  methods: {
    submitPost() {
      const postData = {
        title: this.title,
        content: this.content
      };
      
      // Using the addBlogPost function to make API call
      addBlogPost(postData)
        .then(response => {
          console.log('Post created successfully:', response.data);
          // Optionally reset form fields after successful submission
          this.title = '';
          this.content = '';
        })
        .catch(error => {
          console.error('Error creating post:', error);
        });
    }
  }
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
