<template>
  <div class="h-screen flex items-center justify-center bg-gray-200">
    <div class="text-center">
      <h1 class="text-4xl font-bold text-primary mb-4">Visitors</h1>
      <p class="text-6xl font-extrabold" :class="{ 'animate-pulse': isAnimating }">{{ visitors }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnimatedCounter',
  data() {
    return {
      visitors: 0,
      isAnimating: true,
    };
  },
  mounted() {
    this.startCounter();
  },
  methods: {
    startCounter() {
      let count = 0;
      const target = 1000; // Replace with your target number
      const speed = 20; // Speed of counting
      const interval = setInterval(() => {
        if (count < target) {
          count += 1;
          this.visitors = count;
        } else {
          clearInterval(interval);
          this.isAnimating = false;
        }
      }, speed);
    },
  },
};
</script>
