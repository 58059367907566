<template>
  <div class="container mx-auto py-8">
    <h1 class="text-3xl font-bold mb-6">{{ product.name }}</h1>
    <CarouselComponent :images="product.images" />
    <p class="text-lg mt-4">{{ product.description }}</p>
    <p class="text-xl font-bold mt-2">Price: ${{ product.price }}</p>

    <!-- Size Selection -->
    <div class="mt-4">
      <h3 class="text-lg font-medium">Available Sizes</h3>
      <div class="flex space-x-2 mt-2">
        <button
          v-for="size in product.sizes"
          :key="size"
          @click="selectSize(size)"
          :class="{
            'bg-blue-600 text-white': selectedSize === size,
            'bg-gray-200': selectedSize !== size
          }"
          class="px-4 py-2 rounded-lg"
        >
          {{ size }}
        </button>
      </div>
    </div>

     <div class="mt-4">
      <label for="quantity" class="block font-medium text-gray-700">Quantity</label>
      <input
        type="number"
        v-model="quantity"
        min="1"
        class="mt-2 border border-gray-300 rounded-lg p-2 w-24"
      />
    </div>

    <!-- Color Selection -->
    <div class="mt-4">
      <h3 class="text-lg font-medium">Available Colors</h3>
      <div class="flex space-x-2 mt-2">
        <button
          v-for="color in product.colors"
          :key="color"
          @click="selectColor(color)"
          :style="{ backgroundColor: color }"
          :class="{
            'border-2 border-indigo-600': selectedColor === color,
            'border': selectedColor !== color
          }"
          class="w-10 h-10 rounded-full cursor-pointer"
        ></button>
      </div>
    </div>

     <div class="mt-6">
      <button
        @click="addToCart"
        :disabled="!canAddToCart"
        :class="{
          'bg-gray-400 cursor-not-allowed': !canAddToCart,
          'bg-indigo-600 text-white': canAddToCart
        }"
        class="px-6 py-3 rounded-lg font-medium focus:outline-none"
      >
        Add to Cart
      </button>
    </div>
  </div>
</template>

<script>
import CarouselComponent from "@/components/media/Carousel.vue";
import products from "@/assets/products.json";

export default {
  components: {
    CarouselComponent,
  },
  data() {
    return {
      product: null,
    };
  },
  computed: {
    // Check if the add to cart button should be enabled
    canAddToCart() {
      console.log("canAddToCart");
      return this.selectedSize && this.selectedColor;
    },
    outOfStock() {
      console.log("out of stock")
      return !this.selectedSize || !this.selectedColor;
    },
  },
  created() {
    const productId = this.$route.params.id;
    this.product = products.find(product => product.id === parseInt(productId));
  },
  methods: {
    selectSize(size) {
      this.selectedSize = size;
      console.log(this.selectedSize);
    },
    selectColor(color) {
      this.selectedColor = color;
      console.log(this.selectedColor);
    },
    addToCart() {
      if (this.selectedSize && this.selectedColor) {
        const cartItem = {
          productId: this.product.id,
          name: this.product.name,
          price: this.product.price,
          size: this.selectedSize,
          color: this.selectedColor,
          quantity: this.quantity,
        };
        this.cart.push(cartItem);
        alert(`${this.quantity} item(s) added to the cart.`);
      }
    },
  },
};
</script>

<style scoped>
/* Add styles for your product page */
</style>
  