<template>
  <div :class="computedClass.container">
    <h1>Your Profile</h1>
    <div v-if="user">
      <p><strong>Username:</strong> {{ user.username }}</p>
      <p><strong>Email:</strong> {{ user.email }}</p>
      <p><strong>Member Since:</strong> {{ formatDate(user.createdAt) }}</p>
      <!-- Add more profile fields as needed -->
    </div>
    <div v-else>
      <p>Loading profile information...</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { useTheme } from '@/composables/useTheme';

export default {
  setup() {
    const { computedClass } = useTheme();
    return {
      computedClass,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user, // Get user data from Vuex
    }),
  },
  methods: {
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
  },
};
</script>

<style scoped>
/* Styling based on your theme composable */
.container {
  padding: 20px;
}
</style>
