export default function vortexRipple(el, _options = {}) {
  const canvas = el.tagName === 'CANVAS' ? el : document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (el.tagName !== 'CANVAS') {
    const { width, height } = el.getBoundingClientRect();
    canvas.width = width;
    canvas.height = height;

    el.style.position = 'relative';
    canvas.style.position = 'absolute';
    canvas.style.top = 0;
    canvas.style.left = 0;
    canvas.style.pointerEvents = 'none';

    el.appendChild(canvas);
  }

  let time = 0;

function draw() {
    if (canvas.width === 0 || canvas.height === 0) {
        requestAnimationFrame(draw);
        return;
    }
    const w = canvas.width;
    const h = canvas.height;
    ctx.clearRect(0, 0, w, h);

    const cx = w / 2;
    const cy = h / 2;

    const rings = 25;
    const segments = 60;
    const maxRadius = Math.min(w, h) * 0.4;

    for (let r = 0; r < rings; r++) {
      const radius = (r / rings) * maxRadius;
      for (let i = 0; i < segments; i++) {
        const angle = (i / segments) * Math.PI * 2;
        const offset = Math.sin(time * 0.05 + r * 0.2 + i * 0.1) * 5;

        const x = cx + (radius + offset) * Math.cos(angle);
        const y = cy + (radius + offset) * Math.sin(angle);

        ctx.fillStyle = `rgba(255, 255, 255, 0.03)`;
        ctx.beginPath();
        ctx.arc(x, y, 1.5, 0, Math.PI * 2);
        ctx.fill();
      }
    }

    time++;
    requestAnimationFrame(draw);
  }

  draw();
}
