<template>
  <div class="chart-container">
    <Pie :chart-data="formattedChartData" :options="mergedOptions" />
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs';
import { defineComponent, computed } from 'vue';
import { merge } from 'lodash';

export default defineComponent({
  name: 'PieChart',
  components: {
    Pie,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: String,
      default: 'light',
    },
  },
  setup(props) {
    const defaultOptions = computed(() => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
          labels: {
            color: props.theme === 'dark' ? '#fff' : '#000',
          },
        },
        tooltip: {
          backgroundColor: props.theme === 'dark' ? '#333' : '#fff',
          titleColor: props.theme === 'dark' ? '#fff' : '#000',
          bodyColor: props.theme === 'dark' ? '#fff' : '#000',
        },
      },
    }));

    const mergedOptions = computed(() =>
      merge(defaultOptions.value, props.options)
    );

    const formattedChartData = computed(() => ({
      ...props.chartData,
      datasets: props.chartData.datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: dataset.backgroundColor || [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
        ],
        borderColor: dataset.borderColor || [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
        ],
        hoverBackgroundColor: dataset.hoverBackgroundColor || [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
        ],
      })),
    }));

    return {
      mergedOptions,
      formattedChartData,
    };
  },
});
</script>

<style scoped>
.chart-container {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
