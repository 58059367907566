<template>
  <div :class="containerClass">
    <h2>Register</h2>
    <form @submit.prevent="handleRegister">
      <div>
        <label for="username">Username</label>
        <input
          type="text"
          id="username"
          v-model="username"
          placeholder="Enter your username"
          :class="inputClass"
          required
        />
      </div>
      <div>
        <label for="password">Password</label>
        <input
          type="password"
          id="password"
          v-model="password"
          placeholder="Enter your password"
          :class="inputClass"
          required
        />
      </div>
      <button
        type="submit"
        :disabled="loading"
        :class="buttonClass"
      >
        Register
      </button>
      <p v-if="error" class="error-message text-red-500">{{ error }}</p>
      <p v-if="success" class="success-message text-green-500">Registration successful!</p>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useAuth } from '@/composables/useAuth'; // Assuming the correct path to useAuth.js
import { useTheme } from '@/composables/useTheme'; // Assuming the correct path to useTheme.js

export default {
  setup() {
    const { register, loading, error, success } = useAuth();
    const { theme, containerClass, buttonClass, inputClass } = useTheme();

    const username = ref('');
    const password = ref('');

    const handleRegister = async () => {
      await register(username.value, password.value);
    };

    return {
      username,
      password,
      loading,
      error,
      success,
      handleRegister,
      containerClass,
      buttonClass,
      inputClass,
      theme,
    };
  },
};
</script>

<style scoped>
.error-message {
  margin-top: 10px;
}

.success-message {
  margin-top: 10px;
}
</style>
