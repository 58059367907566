<script setup>
import RGBGhostEffect from '@/components/effects/RGBGhostEffect.vue';
import logo from '@/assets/images/logo_mk_ii.png'
</script>

<template>
    <RGBGhostEffect :src="logo" />

    <div class="p-6 bg-neutral-light min-h-screen">
        
        <h1 class="text-4xl font-bold text-primary mb-6">Welcome to Expected Maximum</h1>
        <p class="text-lg text-neutral-dark">
            Use navbar to navigate through the site.
        </p>
 
    </div>


</template>

<script>
//import AdComponent from '@/components/media/AdComponent.vue';
//import useTheme from '@/composables/useTheme';
//This is your personal website's homepage. Use the navbar to navigate through the site. TESTING TO SEE IF THIS UPDATES...


export default {
    components: {
      //  AdComponent
    },
    name: 'HomeView',
}
</script>

<style scoped>
.home-view {
    padding: 20px;
}
</style>
