<template>
  <div class="p-4">
    <h2 class="text-xl font-semibold mb-4">Messages</h2>
    <ul v-if="messages.length" class="space-y-2">
      <div
        v-for="message in messages"
        :key="message.id"
        class="bg-gray-100 p-2 rounded mb-2"
      >
        <div><strong>{{ message.name }}</strong>: {{ message.message }}</div>
        <div class="text-sm italic text-gray-500 mt-1">{{ formatTimestamp(message.timestamp) }}</div>
    </div>
    
    </ul>
    <p v-else>No messages yet. Be the first to say something nice!</p>
  </div>
</template>

<script>
//import api from "@/services/api";
import { getMessages, deleteMessage } from "@/services/api";

export default {
  data() {
  return {
    messages: []
    };
  },
  methods: {
    async fetchMessages() {
      try {
        const res = await getMessages();
        console.log('Messages:', res.data);
        this.messages = res.data;
      } catch (err) {
        console.error('Error fetching messages:', err);
      }
    },
    async removeMessage(id) {
      await deleteMessage(id);
      this.fetchMessages();
    },
    formatTimestamp(ts) {
      const date = new Date(ts);
      return date.toLocaleString(); // or toLocaleDateString(), etc.
     }
  },
  created() {
    this.fetchMessages();
  }
};
</script>
