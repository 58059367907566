<template>
  <div class="file-list">
    <h2>Uploaded Files</h2>
    <ul>
      <li v-for="file in files" :key="file._id">
        {{ file.filename }}
        <button @click="download(file._id)">Download</button>
        <!-- Add buttons or actions for viewing and visualizing if needed -->
        <button @click="visualize(file._id)">Visualize</button>
      </li>
    </ul>
    <div v-if="error" class="error">{{ error }}</div>
  </div>
</template>

<script>
import { listFiles, downloadFile } from '@/services/api';

export default {
  data() {
    return {
      files: [], // Array to hold the list of files
      error: null, // Error message display
    };
  },
  methods: {
    // Method to fetch the list of files from the backend
    fetchFiles() {
      listFiles()
        .then(response => {
          this.files = response.data; // Assuming response is an array of files
        })
        .catch(error => {
          console.error('Error fetching files:', error);
          this.error = 'Failed to fetch files. Please try again later.';
        });
    },
    // Method to handle downloading a file
    download(fileId) {
      downloadFile(fileId)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'file.csv'); // Adjust filename as needed
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(error => {
          console.error('Error downloading file:', error);
          this.error = 'Failed to download file. Please try again later.';
        });
    },
    // Placeholder method to handle visualization (to be implemented)
    visualize(fileId) {
      console.log('Visualize file:', fileId);
      // Logic for visualization can be added here
      // This could involve emitting an event or changing the application state
    },
  },
  // Lifecycle hook to fetch files when the component is created
  created() {
    this.fetchFiles();
  },
};
</script>

<style scoped>
.file-list {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  margin: 20px auto;
}
ul {
  list-style: none;
  padding: 0;
}
li {
  margin: 10px 0;
}
button {
  margin-left: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}
button:hover {
  background-color: #45a049;
}
.error {
  color: red;
  margin-top: 10px;
}
</style>
