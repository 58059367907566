<template>
  <div class="file-preview" role="document">
    <h2 class="file-preview-title">{{ fileName }}</h2>
    <div class="file-content" v-if="!error && !loading">
      <!-- Display the content of the file based on its type -->
      <pre v-if="isTextFile">{{ fileContent }}</pre>
      <img v-else-if="isImageFile" :src="fileContent" alt="File Preview" />
      <!-- You can add more conditions for other file types if needed -->
    </div>
    <div class="file-error" v-if="error">
      {{ error }}
    </div>
    <div class="file-loading" v-if="loading">
      Loading file content...
    </div>
    <!-- Button to download the file -->
    <button v-if="!loading && !error" @click="downloadFile" class="file-download-btn">Download File</button>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { downloadFile as downloadFileAPI } from '../../services/api';

export default {
  name: 'FilePreview',
  props: {
    fileName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    // Reactive state variables
    const fileContent = ref('');
    const error = ref(null);
    const loading = ref(false);

    // Determine file type for conditional rendering
    const isTextFile = ref(false);
    const isImageFile = ref(false);

    // Check the file type based on its extension
    const checkFileType = () => {
      if (props.fileName.endsWith('.txt')) {
        isTextFile.value = true;
      } else if (props.fileName.endsWith('.jpg') || props.fileName.endsWith('.png')) {
        isImageFile.value = true;
      }
      // Add more conditions for other file types if necessary
    };

    // Function to simulate fetching file content
    const fetchFileContent = () => {
      loading.value = true;
      error.value = null;

      // Example of setting content directly
      setTimeout(() => {
        fileContent.value = 'Sample file content here...'; // Replace with actual file content fetching logic
        loading.value = false;
      }, 1000);
    };

    // Function to handle file downloading
    const downloadFile = () => {
      const fileId = props.fileName; // Assume file ID is derived from the fileName
      downloadFileAPI(fileId)
        .then((response) => {
          // Create a download link for the file
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', props.fileName); // Set download filename
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.error('Error downloading file:', err);
          error.value = 'Failed to download file';
        });
    };

    // Lifecycle hooks
    onMounted(() => {
      checkFileType();
      fetchFileContent();
    });

    onBeforeUnmount(() => {
      // Perform any necessary cleanup
    });

    return {
      fileContent,
      error,
      loading,
      isTextFile,
      isImageFile,
      downloadFile,
    };
  },
};
</script>

<style scoped>
.file-preview {
  border: 1px solid var(--border-color, #ccc);
  padding: 16px;
  margin: 16px 0;
  background-color: var(--background-color, #fff);
}

.file-preview-title {
  font-size: 20px;
  margin-bottom: 8px;
  color: var(--primary-text-color, #333);
}

.file-content {
  margin-bottom: 16px;
  color: var(--secondary-text-color, #666);
}

.file-error {
  color: var(--error-color, red);
}

.file-loading {
  color: var(--loading-color, blue);
}

.file-download-btn {
  background-color: var(--button-background-color, #4CAF50);
  color: var(--button-text-color, white);
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.file-download-btn:hover {
  background-color: var(--button-hover-background-color, #45a049);
}
</style>
