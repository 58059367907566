<template>
  <div class="textured-card">
    <component
      :is="cardTexture.component"
      v-if="cardTexture.component"
      v-bind="cardTexture.props"
      class="texture-layer"
    />
    <div class="card-content">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useTheme } from '@/composables/useTheme';

const { currentTextures } = useTheme();

const cardTexture = computed(() => currentTextures.value.card);
</script>

<style scoped>
.textured-card {
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  padding: 1.5rem;
  background-color: var(--card-bg-color);
  color: var(--card-text-color);
  border: 1px solid var(--border-color);
  box-shadow: 0 4px 10px var(--shadow-color);
}

.texture-layer {
  position: absolute;
  inset: 0;
  z-index: 0;
  pointer-events: none;
  opacity: 0.4;
}

.card-content {
  position: relative;
  z-index: 1;
}
</style>
