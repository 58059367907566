// src/services/api.js

import axios from 'axios';
//axios.defaults.baseURL = '/api'; // Adjust if your API has a different base path

//axios.defaults.baseURL = import.meta.env.VITE_API_URL || '/api'; // fallback for local dev
//axios.defaults.baseURL = process.env.VUE_APP_API_URL || '/api';

//axios.defaults.baseURL = process.env.NODE_ENV === 'production'
 // ? 'http://18.188.219.114:3000/api'
//  : process.env.VUE_APP_API_URL || '/api';

axios.defaults.baseURL = process.env.NODE_ENV === 'production'
  ? 'https://api.expectedmaximum.com'
  : process.env.VUE_APP_API_URL;
  
// 
// Add Authorization header if a token exists in local storage
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
// Function to add a new blog post (existing functionality)
export function addBlogPost(postData) {
  return axios.post('/blogposts', postData);
}

// Function to get all blog posts (existing functionality)
export function getBlogPosts() {
  return axios.get('/blogposts');
}

export function getMessages() {
  return axios.get('/nicemessages');
}

export function addMessage(messageData) {
  return axios.post('/nicemessages', messageData);
}

export function deleteMessage(id) {
  return axios.delete(`/nicemessages/${id}`);
}

// Function to upload a CSV file (new functionality)
export function uploadFile(file) {
  const formData = new FormData();
  formData.append('file', file);

  return axios.post('/files/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}



// Function to fetch processed data for a specific file (new functionality)
export function fetchProcessedData(fileId) {
  return axios.get(`/files/data/${fileId}`);
}

// Function to list all uploaded files (new functionality)
export function listFiles() {
  return axios.get('/files');
}

// Function to download a specific file (new functionality)
export function downloadFile(fileId) {
  return axios.get(`/files/download/${fileId}`, {
    responseType: 'blob', // Important for downloading binary files
  });
}

// New Function to login a user (authentication functionality)
export function loginUser(credentials) {
  return axios.post('/auth/login', credentials)
    .then(response => {
      const token = response.data.token;
      localStorage.setItem('token', token); // Store token in local storage
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // Set authorization header
      return response;
    });
}

// New Function to register a user (authentication functionality)
export function registerUser(userData) {
  return axios.post('/auth/register', userData);
}

// Function to logout a user
export function logoutUser() {
  localStorage.removeItem('token');
  console.log(localStorage)// Remove token from local storage
  console.log('[api] logout user')
  delete axios.defaults.headers.common['Authorization']; // Remove authorization header
}

// Function to get the currently authenticated user's information
export function getCurrentUser() {
  return axios.get('/auth/me'); // Endpoint to fetch current user's info if needed
}