export default function crtFlicker(el, _options = {}) {
  const canvas = el.tagName === 'CANVAS' ? el : document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (el.tagName !== 'CANVAS') {
    const { width, height } = el.getBoundingClientRect();
    canvas.width = width;
    canvas.height = height;

    el.style.position = 'relative';
    canvas.style.position = 'absolute';
    canvas.style.top = 0;
    canvas.style.left = 0;
    canvas.style.pointerEvents = 'none';

    el.appendChild(canvas);
  }

  let time = 0;

  function draw() {
    const w = canvas.width;
    const h = canvas.height;

    ctx.clearRect(0, 0, w, h);

    // Scanlines
    for (let y = 0; y < h; y += 2) {
      ctx.fillStyle = `rgba(0, 0, 0, 0.1)`;
      ctx.fillRect(0, y, w, 1);
    }

    // Random flicker
    const flicker = Math.random() * 0.1;
    ctx.fillStyle = `rgba(255, 255, 255, ${flicker})`;
    ctx.fillRect(0, 0, w, h);

    // RGB shift/glow
    const glow = Math.sin(time * 0.1) * 2;
    ctx.strokeStyle = `rgba(0, 255, 255, 0.08)`;
    ctx.lineWidth = glow;
    ctx.strokeRect(0, 0, w, h);

    time++;
    requestAnimationFrame(draw);
  }

  draw();
}
