import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';

import './assets/styles/tailwind.css';
import './assets/styles/main.css';

import './assets/styles/themes.css';  // Ensure this is correctly loaded


if (process.env.NODE_ENV === 'development') {
  window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app;
}

// Sentry setup for error logging
// import * as Sentry from '@sentry/vue';
// import { BrowserTracing } from '@sentry/tracing';

const app = createApp(App);
console.log('Current environment:', process.env.NODE_ENV);
console.log("Base API URL:", process.env.VUE_APP_API_URL);

// Sentry.init({
//   app,
//   dsn: 'YOUR_SENTRY_DSN', // Replace with your actual DSN from Sentry
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router), // Use directly from @sentry/vue
//       tracingOrigins: ['localhost', /^\//], // Track routes
//     }),
//   ],
//   tracesSampleRate: 1.0, // Adjust this value as needed
// });

app.use(store);
app.use(router).mount('#app');
