<template>
  <div class="p-4 bg-white rounded-lg shadow">
    <h2 class="text-xl font-semibold mb-4">Say Something Nice</h2>
    <form @submit.prevent="submitMessage">
      <input v-model="name" type="text" placeholder="Your Name" class="border p-2 w-full rounded mb-2">
      <textarea v-model="message" placeholder="Say something nice..." class="border p-2 w-full rounded mb-2"></textarea>
      <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded">Submit</button>
    </form>
  </div>
</template>

<script>
//import api from "@/services/api";
import { addMessage } from "@/services/api";

export default {
  data() {
    return {
      name: "",
      message: "",
    };
  },
  methods: {
    async submitMessage() {
      if (!this.message.trim()) return;

      const payload = {
        //text: `${this.name || "Anonymous"}: ${this.message}`,
        name: this.name || "Anonymous",
        message: this.message,
      };

      try {
        await addMessage(payload);
        this.name = "";
        this.message = "";
        this.$emit("message-added");
      } catch (error) {
        console.error("Error submitting message:", error);
      }
    },
  },
};
</script>
