<template>
  <div class="carousel-container relative">
    <div class="carousel-images flex overflow-hidden">
      <img
        v-for="(image, index) in images"
        :key="index"
        :src="image"
        alt="Carousel Image"
        class="w-full object-cover transition-transform duration-700 ease-in-out"
        :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
      />
    </div>
    
    <!-- Controls -->
    <button @click="prev" class="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2">
      Prev
    </button>
    <button @click="next" class="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2">
      Next
    </button>

    <!-- Dots Indicator -->
    <div class="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex space-x-2">
      <span
        v-for="(image, index) in images"
        :key="index"
        @click="goToSlide(index)"
        class="block w-3 h-3 rounded-full bg-gray-400"
        :class="{ 'bg-blue-500': currentIndex === index }"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentIndex: 0
    };
  },
  methods: {
    next() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
    prev() {
      this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
    },
    goToSlide(index) {
      this.currentIndex = index;
    }
  }
};
</script>

<style scoped>
.carousel-container {
  width: 100%;
  position: relative;
}

.carousel-images img {
  min-width: 100%;
  height: 400px; /* Adjust according to your layout */
}
</style>
