<template>
  <div ref="wrapper" class="visual-effect-wrapper">
    <slot />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import effectRegistry from '../../utils/visual-effects/effectRegistry.js';

const props = defineProps({
  effects: {
    type: [Array, String],
    required: true,
  },
  options: {
    type: Object,
    default: () => ({}),
  },
});

const wrapper = ref(null);

// Normalize to array
const effectList = Array.isArray(props.effects) ? props.effects : [props.effects];

onMounted(() => {
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      effectList.forEach((name) => {
        const effectFn = effectRegistry[name];
        if (!effectFn) {
          console.warn(`Effect "${name}" not found.`);
          return;
        }
        effectFn(wrapper.value);
      });
    });
  });
});

</script>

<style scoped>
.visual-effect-wrapper {
  position: relative;
  width: 100%;
  height: auto; /* <-- was probably missing */
  display: inline-block; /* optional but recommended */
}

</style>
