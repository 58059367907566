<template>
  <button class="textured-button" v-bind="$attrs">
    <component
      :is="buttonTexture.component"
      v-if="buttonTexture.component"
      v-bind="buttonTexture.props"
      class="texture-layer"
    />
    <span class="button-content">
      <slot />
    </span>
  </button>
</template>

<script setup>
import { computed } from 'vue';
import { useTheme } from '@/composables/useTheme';

const { currentTextures } = useTheme();

const buttonTexture = computed(() => currentTextures.value.button);
</script>

<style scoped>
.textured-button {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  border: 1px solid var(--border-color);
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.texture-layer {
  position: absolute;
  inset: 0;
  z-index: 0;
  pointer-events: none;
  opacity: 0.3;
}

.button-content {
  position: relative;
  z-index: 1;
}
</style>
