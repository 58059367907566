<template>
  <div class="box-plot-container">
    <canvas ref="boxPlotCanvas"></canvas>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { Chart, registerables } from 'chart.js';
import { CandlestickController, CandlestickElement, OhlcController, OhlcElement } from 'chartjs-chart-financial';

// Register Chart.js components and financial chart types
Chart.register(...registerables, CandlestickController, CandlestickElement, OhlcController, OhlcElement);

export default {
  name: 'BoxPlot',
  setup() {
    const boxPlotCanvas = ref(null); // Reactive reference to the canvas element
    let chartInstance = null;

    onMounted(() => {
      const ctx = boxPlotCanvas.value.getContext('2d');
      
      // Initialize the Chart instance
      chartInstance = new Chart(ctx, {
        type: 'candlestick', // or 'ohlc' depending on your use case
        data: {
          datasets: [{
            label: 'Sample Data',
            data: [
              // Replace with your data
              { x: new Date(), o: 100, h: 110, l: 90, c: 105 },
              { x: new Date(), o: 105, h: 115, l: 95, c: 100 }
            ],
          }],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
            },
          },
        },
      });
    });

    onBeforeUnmount(() => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    });

    return {
      boxPlotCanvas,
    };
  },
};
</script>

<style scoped>
.box-plot-container {
  width: 100%;
  height: 400px;
}
</style>
