<template>
  <nav :class="computedClass.navbar">
    <div class="navbar-left">
      <!-- Main Links for all users -->
      <a href="/" :class="computedClass.link">Home</a>
      <a href="/about" :class="computedClass.link">About</a>
      <a href="/saysomethingnice" :class="computedClass.link">Say Something Nice</a>
      <template v-if="showPostLinks">
       <a href="/blog" :class="computedClass.link">Blog</a>
      </template>
      
      <template v-if="showDashboardLink">
        <a href="/dashboard" :class="computedClass.link">Dashboard</a>
      </template>
      <template v-if="showShopLink">
        <a href="/shop" :class="computedClass.link">Shop</a>
      </template>
  
      <template v-if="showDemoLink">
        <a href="/demo" :class="computedClass.link">Demo</a>
        <a href="/theme-demo" :class="computedClass.link">Theme Demo</a>
        <a href="/demo-mkii" :class="computedClass.link">Demo mkii</a>
      </template>
    </div>

    <div class="navbar-right" >
      <template v-if="isAuthenticated && showAuthLink">
        <!-- Show these when logged in -->
        <a href="/profile" :class="computedClass.link">Profile</a>
        <button @click="logout" :class="computedClass.button">Sign Out</button>
      </template>
      <template v-else-if="showAuthLink">
        <!-- Show these when not logged in -->
        <a href="/login" :class="computedClass.link">Sign In</a>
        <a href="/register" :class="computedClass.link">Sign Up</a>
      </template>
    </div>
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { useTheme } from '@/composables/useTheme'; // Assuming you're using a theme composable
import { useAuth } from '@/composables/useAuth'; // Adjust path if necessary

export default {
  name: "MainNavbar",
  data() {
    return {
      showAuthLink: process.env.VUE_APP_SHOW_AUTH_ROUTES === 'true',
      showBlogLink: process.env.VUE_APP_SHOW_BLOG_ROUTES === 'true',
      showDemoLink: process.env.VUE_APP_SHOW_DEMO_ROUTES === 'true',
      showShopLink: process.env.VUE_SHOW_SHOP_ROUTES === 'true',
      showDashboardLink: process.env.VUE_APP_SHOW_DASHBOARD_ROUTES === 'true',
      showPostLink: process.env.VUE_APP_SHOW_BLOG_POST_ROUTES === 'true',
    }
  },
  setup() {
    const { computedClass } = useTheme(); // Theme setup
    const { token, logout } = useAuth(); // Get token and logout function from useAuth composable

    // Determine if user is logged in based on token presence
    const isLoggedIn = !!token.value;
    return {
      computedClass,
      logout,
      isLoggedIn, // Expose logged-in state for the template
    };
  },
  computed: {
    // Map the authentication state from Vuex store
    ...mapState({
      token: state => state.auth.token,  // Assuming your auth token is in the `auth` module
    }),
    isAuthenticated() {
      return !!this.token; // Derived state to determine if the user is logged in
    },
  },
  created() {
    console.log("[MainNavbar] Navbar created. Checking auth state...");
    // Check authentication state when component is created
    this.checkAuthState()
      .then(() => console.log("[MainNavbar] Auth state checked successfully."))
      .catch((err) => console.error("[MainNavbar] Error checking auth state:", err));
  },
  watch: {
    // Watch the isAuthenticated computed property and log changes
 //   isAuthenticated(newValue) {

  //    },
  },
  methods: {
    ...mapActions(['checkAuthState', 'logout']), // Map Vuex actions
  },
};
</script>


<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ddd;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.navbar-left a {
  margin-right: 15px;
}

.navbar-right a, .navbar-right button {
  margin-left: 15px;
  text-decoration: none;
  cursor: pointer;
}
</style>
