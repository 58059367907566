<template>
  <div class="bg-gradient-to-r from-blue-400 to-purple-600 h-screen flex items-center justify-center">
    <div class="backdrop-blur-sm bg-white/30 p-6 rounded-xl shadow-lg max-w-sm">
      <h2 class="text-2xl font-bold text-white">Glassmorphism Card</h2>
      <p class="text-white mt-4">This card has a cool glass effect, making it stand out against the background.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GlassmorphismCard',
};
</script>
