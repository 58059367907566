<template>
  <div :class="themeClasses.container">
    <!-- Toolbar for formatting options -->
    <div class="toolbar">
      <button @click="applyFormatting('bold')"><b>B</b></button>
      <button @click="applyFormatting('italic')"><i>I</i></button>
      <button @click="applyFormatting('underline')"><u>U</u></button>
      <button @click="embedImage">Embed Image</button>
      <button @click="addCodeBlock">Insert Code Block</button>
      <button @click="openChartModal">Insert Chart</button>
    </div>

    <!-- Textarea for blog post content -->
    <div class="editor">
      <textarea 
        v-model="content" 
        :class="themeClasses.input"
        class="blog-editor"
        placeholder="Write your blog post here...">
      </textarea>
    </div>

    <!-- Preview Section (Optional) -->
    <div class="preview">
      <h3 :class="themeClasses.label">Preview:</h3>
      <div v-html="parsedContent"></div>
    </div>

    <!-- Submit Button -->
    <div class="submit-section">
      <button @click="submitPost" :class="themeClasses.submitButton">Submit Post</button>
    </div>

    <!-- Chart Modal (placeholder) -->
    <div v-if="showChartModal" class="modal">
      <div :class="themeClasses.container">
        <h3 :class="themeClasses.label">Select Chart and Dataset</h3>
        <!-- Placeholder: You can implement chart options here later -->
        <button @click="closeChartModal" :class="themeClasses.submitButton">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useTheme } from '../composables/useTheme.js'; // Importing your useTheme composable

export default {
  setup() {
    const { isDark, toggleTheme, computedClass } = useTheme(); // Use the theme logic
    return {
      isDark,
      toggleTheme,
      themeClasses: computedClass, // Exposing theme-based dynamic classes
    };
  },
  data() {
    return {
      content: '', // Holds the blog content
      showChartModal: false, // Controls chart modal visibility
      parsedContent: '' // Optional parsed content for the preview
    };
  },
  methods: {
    applyFormatting(format) {
      // Simple placeholder for formatting (bold, italic, underline)
      if (format === 'bold') {
        this.content += '**Bold Text**'; // Example: Replace this with more advanced logic
      } else if (format === 'italic') {
        this.content += '*Italic Text*';
      } else if (format === 'underline') {
        this.content += '<u>Underline Text</u>';
      }
    },
    embedImage() {
      // Placeholder logic for embedding an image
      this.content += '![](image-url)';
    },
    addCodeBlock() {
      // Placeholder logic for adding a code block
      this.content += '```code block```';
    },
    openChartModal() {
      // Opens the chart modal
      this.showChartModal = true;
    },
    closeChartModal() {
      // Closes the chart modal
      this.showChartModal = false;
    },
    submitPost() {
      // Placeholder logic for submitting the post
      console.log('Post Submitted:', this.content);
    }
  }
};
</script>

<style scoped>
/* Basic styles */
.toolbar {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

textarea.blog-editor {
  width: 100%;
  height: 200px;
  padding: 10px;
}

.submit-section {
  margin-top: 20px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}
</style>
