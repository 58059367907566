<template>
  <div class="p-6 bg-gray-100 min-h-screen">
    <h1 class="text-4xl font-bold text-center mb-6">Component Demolition</h1>

    <!-- Glassmorphism Card -->
    <div class="mb-8">
      <GlassmorphismCard />
    </div>

    <!-- Animated Gradient -->
    <div class="mb-8">
      <AnimatedGradient />
    </div>

    <!-- Neumorphism Button -->
    <div class="mb-8 flex justify-center">
      <NeumorphismButton />
    </div>

    <!-- Responsive Image Gallery -->
    <div class="mb-8">
      <ResponsiveImageGallery />
    </div>

    <!-- Parallax Effect -->
    <div class="mb-8">
      <ParallaxEffect />
    </div>

    <!-- 3D Tilt Card -->
    <div class="mb-8 flex justify-center">
      <TiltCard />
    </div>

    <!-- Animated Counter -->
    <div class="mb-8">
      <AnimatedCounter />
    </div>

    <!-- Stock Ticker -->
    <div class="mb-8">
    </div>

    <div class="mb-8">
      <Carousel :images="carouselImages" />
    </div>


  </div>
</template>

<script>
import GlassmorphismCard from '@/components/cards/GlassmorphismCard.vue';
import AnimatedGradient from '@/components/effects/AnimatedGradient.vue';
import NeumorphismButton from '@/components/common/NeumorphismButton.vue';
import ResponsiveImageGallery from '@/components/media/ResponsiveImageGallery.vue';
import ParallaxEffect from '@/components/effects/ParallaxEffect.vue';
import TiltCard from '@/components/cards/TiltCard.vue';
import AnimatedCounter from '@/components/effects/AnimatedCounter.vue';
// import StockTicker from '@/components/analytics/StockTicker.vue';
import Carousel from '@/components/media/Carousel.vue';

export default {
  name: 'ComponentDemolition',
  components: {
    GlassmorphismCard,
    AnimatedGradient,
    NeumorphismButton,
    ResponsiveImageGallery,
    ParallaxEffect,
    TiltCard,
    AnimatedCounter,
   // StockTicker,
    Carousel,
  },
  data() {
    return {
      // Define the images to be shown in the carousel
      carouselImages: [
        require("@/assets/images/imageone.png"),
        require("@/assets/images/imagetwo.jpg"),
        require("@/assets/images/imagethree.png"),
      ],
    };
  },
};
</script>

<style scoped>
.mb-8 {
  margin-bottom: 2rem;
}
</style>
