<template>
  <div class="sankey-chart-container">
    <!-- The container for the Sankey chart -->
    <div ref="sankeyContainer"></div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import Highcharts from 'highcharts';
import Sankey from 'highcharts/modules/sankey';

// Initialize the Sankey module
Sankey(Highcharts);

export default {
  name: 'SankeyChart',
  setup() {
    const sankeyContainer = ref(null); // Using ref to get the DOM element
    let chartInstance = null; // Variable to hold the chart instance

    // Initialize the chart when the component is mounted
    onMounted(() => {
      if (sankeyContainer.value) {
        chartInstance = Highcharts.chart(sankeyContainer.value, {
          chart: {
            type: 'sankey',
          },
          title: {
            text: 'Sankey Diagram',
          },
          series: [
            {
              keys: ['from', 'to', 'weight'],
              data: [
                ['A', 'B', 5],
                ['B', 'C', 3],
                ['C', 'D', 4],
              ],
              name: 'Sankey Series',
            },
          ],
        });
      }
    });

    // Cleanup the chart instance when the component is unmounted
    onUnmounted(() => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    });

    return {
      sankeyContainer, // Return the ref to make it accessible in the template
    };
  },
};
</script>

<style scoped>
.sankey-chart-container {
  max-width: 800px;
  margin: 0 auto;
}
</style>
