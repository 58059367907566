import { createStore } from 'vuex';
import auth from './auth'; // Import the auth module

const store = createStore({
  modules: {
    auth, // Register the auth module
  },
  // You can still add other non-auth related modules or state here
});

export default store;
