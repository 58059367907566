<template>
  <div class="bar-chart-container">
    <Bar :chart-data="formattedChartData" :options="computedChartOptions" />
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import { defineComponent, computed } from 'vue';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default defineComponent({
  name: 'BarChart',
  components: {
    Bar,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: Object,
      default: () => ({
        backgroundColor: '#ffffff',
        fontColor: '#000000',
        gridColor: '#e0e0e0',
        barColor: '#42A5F5',
        barHoverColor: '#64b5f6',
      }),
    },
  },
  setup(props) {
    // Format the chart data to ensure compatibility
    const formattedChartData = computed(() => {
      return {
        ...props.chartData,
        datasets: props.chartData.datasets.map(dataset => ({
          ...dataset,
          backgroundColor: props.theme.barColor,
          hoverBackgroundColor: props.theme.barHoverColor,
        })),
      };
    });

    // Compute chart options based on default or provided options
    const computedChartOptions = computed(() => {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              color: props.theme.gridColor,
            },
            ticks: {
              color: props.theme.fontColor,
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              color: props.theme.gridColor,
            },
            ticks: {
              color: props.theme.fontColor,
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              color: props.theme.fontColor,
            },
          },
          title: {
            display: true,
            text: props.chartOptions.title || 'Bar Chart',
            color: props.theme.fontColor,
            font: {
              size: 16,
            },
          },
        },
        ...props.chartOptions,
      };
    });

    return {
      formattedChartData,
      computedChartOptions,
    };
  },
});
</script>

<style scoped>
.bar-chart-container {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--chart-background-color, #f5f5f5);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
