<template>
  <div>
    <h2>Blog Posts</h2>
    <ul>
      <li v-for="post in posts" :key="post._id">
        <h3>{{ post.title }}</h3>
        <p>{{ post.content }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { getBlogPosts } from '../../services/api.js'; // Importing the getBlogPosts function from api.js

export default {
  data() {
    return {
      posts: []
    };
  },
  created() {
    this.fetchPosts();
  },
  methods: {
    fetchPosts() {
      // Using the getBlogPosts function to make an API call
      getBlogPosts()
        .then(response => {
          this.posts = response.data;
        })
        .catch(error => {
          console.error('Error fetching posts:', error);
        });
    }
  }
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
