<template>
  <div class="animated-gradient"></div>
</template>

<style scoped>
.animated-gradient {
  position: absolute;
  inset: 0;
  z-index: 0;
  pointer-events: none;
  opacity: 0.3;

  background: linear-gradient(-45deg, #ff0080, #7928ca, #2afadf, #00f2fe);
  background-size: 400% 400%;
  animation: gradientShift 12s ease infinite;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
