export default function glowPulse(el, _options = {}) {
  const canvas = el.tagName === 'CANVAS' ? el : document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (el.tagName !== 'CANVAS') {
    const { width, height } = el.getBoundingClientRect();
    canvas.width = width;
    canvas.height = height;

    el.style.position = 'relative';
    canvas.style.position = 'absolute';
    canvas.style.top = 0;
    canvas.style.left = 0;
    canvas.style.pointerEvents = 'none';

    el.appendChild(canvas);
  }

  let time = 0;

  function draw() {
    const w = canvas.width;
    const h = canvas.height;

    ctx.clearRect(0, 0, w, h);

    const pulse = 0.5 + Math.sin(time * 0.05) * 0.5;
    const maxRadius = Math.max(w, h) * 0.75;

    const gradient = ctx.createRadialGradient(
      w / 2,
      h / 2,
      0,
      w / 2,
      h / 2,
      maxRadius * pulse
    );
    gradient.addColorStop(0, `rgba(255, 255, 255, 0.08)`);
    gradient.addColorStop(1, `rgba(255, 255, 255, 0)`);

    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, w, h);

    time++;
    requestAnimationFrame(draw);
  }

  draw();
}
