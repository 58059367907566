<template>
  <canvas ref="canvas" class="noise-pattern"></canvas>
</template>

<script setup>
import { onMounted, ref } from 'vue';

const canvas = ref(null);

const generateNoise = (ctx, width, height, density = 0.3) => {
  const imageData = ctx.createImageData(width, height);
  const buffer = imageData.data;

  for (let i = 0; i < buffer.length; i += 4) {
    const value = Math.random() < density ? 255 : 0;
    buffer[i] = value;     // R
    buffer[i + 1] = value; // G
    buffer[i + 2] = value; // B
    buffer[i + 3] = Math.random() * 50; // Alpha (transparency)
  }

  ctx.putImageData(imageData, 0, 0);
};

onMounted(() => {
  const el = canvas.value;
  if (!el) return;

  const width = window.innerWidth;
  const height = window.innerHeight;

  el.width = width;
  el.height = height;

  const ctx = el.getContext('2d');
  generateNoise(ctx, width, height);
});
</script>

<style scoped>
.noise-pattern {
  position: absolute;
  inset: 0;
  z-index: 0;
  pointer-events: none;
  opacity: 0.15;
  mix-blend-mode: overlay;
}
</style>
