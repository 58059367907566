<template>
  <div class="chart-container">
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
import MapModule from 'highcharts/modules/map';
import Exporting from 'highcharts/modules/exporting';

// Load necessary Highcharts modules
MapModule(Highcharts);
Exporting(Highcharts);

export default defineComponent({
  name: 'BubbleMap',
  components: {
    highcharts: HighchartsVue.component,
  },
  props: {
    mapData: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: 'Bubble Map',
    },
  },
  setup(props) {
    const chartOptions = computed(() => ({
      chart: {
        map: props.mapData,
        height: '100%',
      },
      title: {
        text: props.title,
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: 'bottom',
        },
      },
      colorAxis: {
        min: 0,
        stops: [
          [0.4, '#ffffff'],
          [0.65, '#ffa07a'],
          [1, '#ff6347'],
        ],
      },
      series: [
        {
          type: 'mapbubble',
          mapData: props.mapData,
          name: 'Data Points',
          data: props.data,
          joinBy: ['iso-a3', 'code3'],
          minSize: 4,
          maxSize: '12%',
          tooltip: {
            pointFormat: '{point.name}: {point.z}',
          },
        },
      ],
    }));

    return {
      chartOptions,
    };
  },
});
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
}
</style>
