export default function digitalNoiseFade(el, _options = {}) {
  const canvas = el.tagName === 'CANVAS' ? el : document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (el.tagName !== 'CANVAS') {
    const { width, height } = el.getBoundingClientRect();
    canvas.width = width;
    canvas.height = height;

    el.style.position = 'relative';
    canvas.style.position = 'absolute';
    canvas.style.top = 0;
    canvas.style.left = 0;
    canvas.style.pointerEvents = 'none';

    el.appendChild(canvas);
  }

  let sweepX = 0;

 function draw() {
  const w = canvas.width;
  const h = canvas.height;

  if (w === 0 || h === 0) {
    requestAnimationFrame(draw);
    return;
  }

  const imageData = ctx.createImageData(w, h);
  const data = imageData.data;

  for (let y = 0; y < h; y++) {
    for (let x = 0; x < w; x++) {
      const i = (y * w + x) * 4;
      const inSweep = x < sweepX;

      const noise = Math.random() * 255;
      data[i] = inSweep ? noise : 0;
      data[i + 1] = inSweep ? noise : 0;
      data[i + 2] = inSweep ? noise : 0;
      data[i + 3] = inSweep ? 80 : 0;
    }
  }

  ctx.putImageData(imageData, 0, 0);
  sweepX += 4;
  if (sweepX > canvas.width) sweepX = 0;

  requestAnimationFrame(draw);
}

  draw();
}
