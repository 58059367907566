<template>
  <div class="chart-container">
    <canvas ref="heatmapCanvas"></canvas>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-datalabels';

Chart.register(...registerables);

export default defineComponent({
  name: 'HeatmapChart',
  props: {
    data: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: String,
      default: 'light',
    },
  },
  setup(props) {
    const heatmapCanvas = ref(null);

    const generateHeatmapData = () => {
      // Custom logic to generate heatmap data
      // Convert props.data into a format suitable for heatmap
      const labels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
      const datasets = [
        {
          label: 'Heatmap Dataset',
          data: [
            { x: 'Mon', y: '9am', value: 10 },
            { x: 'Tue', y: '10am', value: 20 },
            { x: 'Wed', y: '11am', value: 30 },
            // More data points
          ],
          backgroundColor: 'rgba(255, 99, 132, 0.6)',
        },
      ];
      return { labels, datasets };
    };

    const createHeatmap = () => {
      const context = heatmapCanvas.value.getContext('2d');
      new Chart(context, {
        type: 'matrix',
        data: generateHeatmapData(),
        options: {
          ...props.options,
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: { type: 'category', labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'] },
            y: { type: 'category', labels: ['9am', '10am', '11am', '12pm'] },
          },
          plugins: {
            tooltip: {
              callbacks: {
                title: () => null,
                label: (tooltipItem) => {
                  return `Value: ${tooltipItem.raw.value}`;
                },
              },
            },
            legend: {
              display: false,
            },
          },
        },
      });
    };

    onMounted(() => {
      createHeatmap();
    });

    return {
      heatmapCanvas,
    };
  },
});
</script>

<style scoped>
.chart-container {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
