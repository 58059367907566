// src/composables/useTheme.js
import { ref, onMounted, computed } from 'vue';
import AnimatedGradientTexture from '@/components/textures/AnimatedGradientTexture.vue';
import NoisePattern from '@/components/textures/NoisePattern.vue';
import ImageTexture from '@/components/textures/ImageTexture.vue';
import gridPaper from '@/assets/textures/grid-paper.png';

export function useTheme() {
  const availableThemes = ['default', 'cool-blue', 'radical'];
  const storedTheme = localStorage.getItem('theme');
  const storedDarkMode = localStorage.getItem('darkMode');

  if (!storedTheme) {
    localStorage.setItem('theme', 'radical');
  }
  if (!storedDarkMode) {
    localStorage.setItem('darkMode', 'light');
  }

  const currentTheme = ref(storedTheme || 'default');
  const isDark = ref(storedDarkMode === 'dark');


  const textureComponentMap = {
    'animated-gradient': {
      component: AnimatedGradientTexture,
      props: {},
    },
    'noise-pattern': {
      component: NoisePattern,
      props: {},
    },
    'grid-paper': {
      component: ImageTexture,
      props: {
        src: gridPaper,
        opacity: 0.2,
        mode: 'repeat',
      },
    },
    'none': {
      component: null,
      props: {},
    },
  };

  const currentTextures = ref({
    background: { component: null, props: {} },
    card: { component: null, props: {} },
    button: { component: null, props: {} }, // <-- Important fix here
  });

  const applyTheme = () => {
    const root = document.documentElement;

    // Clear existing theme classes
    availableThemes.forEach(theme => {
      root.classList.remove(`${theme}-theme`);
    });
    root.classList.remove('dark-theme', 'light-theme');

    // Add the new theme class
    root.classList.add(`${currentTheme.value}-theme`);
    root.classList.add(isDark.value ? 'dark-theme' : 'light-theme');

    // Persist selections
    localStorage.setItem('theme', currentTheme.value);
    localStorage.setItem('darkMode', isDark.value ? 'dark' : 'light');

    const styles = getComputedStyle(root);
    const bgKey = styles.getPropertyValue('--texture-background').replace(/['"]/g, '').trim();
    const cardKey = styles.getPropertyValue('--texture-card').replace(/['"]/g, '').trim();
    const buttonKey = styles.getPropertyValue('--texture-button').replace(/['"]/g, '').trim();

    currentTextures.value.background = textureComponentMap[bgKey] || textureComponentMap['none'];
    currentTextures.value.card = textureComponentMap[cardKey] || textureComponentMap['none'];
    currentTextures.value.button = textureComponentMap[buttonKey] || textureComponentMap['none'];
  };

  const toggleDarkMode = () => {
    isDark.value = !isDark.value;
    applyTheme();
  };

  const setTheme = (theme) => {
    if (availableThemes.includes(theme)) {
      currentTheme.value = theme;
      applyTheme();
    } else {
      console.warn(`Theme "${theme}" is not defined.`);
    }
  };

  onMounted(() => {
    applyTheme();
  });

  const computedClass = computed(() => ({
    container: `p-6 rounded-lg shadow ${isDark.value ? 'bg-gray-900 text-white' : 'bg-white text-black'}`,
    headerFooter: `p-4 ${isDark.value ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`,
    button: `px-4 py-2 rounded-md transition ${isDark.value ? 'bg-blue-600 hover:bg-blue-700 text-white' : 'bg-blue-500 hover:bg-blue-600 text-white'}`,
    text: `${isDark.value ? 'text-gray-300' : 'text-gray-700'}`,
  }));

  return {
    isDark,
    currentTheme,
    availableThemes,
    computedClass,
    toggleDarkMode,
    setTheme,
    currentTextures,
  };
}
