<template>
  <div>
    <AppLayout />
  </div>
</template>

<script>
import AppLayout from './components/layout/AppLayout.vue'; // Correctly importing AppLayout
import { mapActions } from 'vuex';

export default {
  components: {
    AppLayout,
  },
  created() {
    console.log('[App.vue] Created hook triggered. Checking authentication state...');
    this.checkAuthState()
      .then(() => console.log('[App.vue] Authentication check completed successfully.'))
      .catch((err) => console.error('[App.vue] Error during authentication check:', err));
  },
  methods: {
    ...mapActions(['checkAuthState']),
  },
};
</script>
