<template>
  <div class="chart-container">
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
import TreemapModule from 'highcharts/modules/treemap';

TreemapModule(Highcharts);

export default defineComponent({
  name: 'TreemapChart',
  components: {
    highcharts: HighchartsVue.component,
  },
  setup() {
    const chartOptions = ref({
      chart: {
        type: 'treemap',
        height: '100%',
      },
      title: {
        text: 'Treemap Example',
      },
      series: [
        {
          type: 'treemap',
          layoutAlgorithm: 'squarified',
          data: [
            {
              name: 'A',
              value: 6,
              color: '#FF6384',
            },
            {
              name: 'B',
              value: 6,
              color: '#36A2EB',
            },
            {
              name: 'C',
              value: 4,
              color: '#FFCE56',
            },
            {
              name: 'D',
              value: 3,
              color: '#4BC0C0',
            },
            {
              name: 'E',
              value: 2,
              color: '#F7464A',
            },
          ],
        },
      ],
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.value}',
          },
        },
      },
    });

    return {
      chartOptions,
    };
  },
});
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
}
</style>
