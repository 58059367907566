<template>
  <div class="max-w-2xl mx-auto p-6">
      <p class="text-lg text-neutral-dark">
            Testing say something nice updates...
        </p>
    <SaySomethingNiceForm @messageSubmitted="fetchMessages" />
    <NiceMessagesList ref="messageList" />
  </div>
</template>

<script>
import SaySomethingNiceForm from "@/components/forms/SaySomethingNiceForm.vue";
import NiceMessagesList from "@/components/pages/NiceMessagesList.vue";

export default {
  components: {
    SaySomethingNiceForm,
    NiceMessagesList,
  },
  methods: {
    fetchMessages() {
      this.$refs.NiceMessagesList.getMessages();
    },
  },
};
</script>
