<!-- src/components/layout/Footer.vue -->
<template>
  <footer :class="computedClass.footer">
    <div class="container">
      <p>© {{ new Date().getFullYear() }} Expected Maximum. All rights reserved.</p>

      <div class="theme-controls">
        <button @click="toggleDarkMode" :class="computedClass.button">
          Switch to {{ isDark ? 'Light' : 'Dark' }} Mode
        </button>

        <select v-model="selectedTheme" @change="updateTheme" :class="computedClass.input">
          <option v-for="theme in availableThemes" :key="theme" :value="theme">
            {{ theme.charAt(0).toUpperCase() + theme.slice(1) }} Theme
          </option>
        </select>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref } from 'vue';
import { useTheme } from '@/composables/useTheme';

// Use theme composable
const { 
  isDark, 
  toggleDarkMode, 
  setTheme, 
  availableThemes, 
  computedClass, 
  currentTheme 
} = useTheme();

// Bind selected theme
const selectedTheme = ref(currentTheme.value);

// Function to update the theme from select dropdown
const updateTheme = () => {
  setTheme(selectedTheme.value);
};
</script>

<style scoped>
footer {
  padding: 1rem;
  text-align: center;
  border-top: 1px solid var(--border-color);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.theme-controls {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

button, select {
  cursor: pointer;
}

select {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--card-bg-color);
  color: var(--text-color);
}
</style>
