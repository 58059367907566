<template>
  <div class="h-screen flex items-center justify-center bg-gray-100">
    <button class="neumorphic-button p-4 rounded-lg">
      Neumorphic Button
    </button>
  </div>
</template>

<script>
export default {
  name: 'NeumorphismButton',
};
</script>

<style scoped>
.neumorphic-button {
  background-color: #e0e0e0;
  box-shadow: 7px 7px 15px #bebebe, -7px -7px 15px #ffffff;
  transition: all 0.2s ease;
}
.neumorphic-button:active {
  box-shadow: inset 7px 7px 15px #bebebe, inset -7px -7px 15px #ffffff;
}
</style>
