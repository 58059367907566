<template>
  <div class="flex justify-center items-center min-h-screen bg-gray-100">
    <div class="w-full max-w-md bg-white rounded-lg shadow-md p-8">
      <h2 class="text-2xl font-bold text-center mb-6">Login</h2>
      <form @submit.prevent="handleLogin">
        <!-- Standard HTML input for username -->
        <div class="mb-4">
          <label for="username" class="block text-sm font-medium text-gray-700">Username</label>
          <input 
            v-model="username"
            type="text" 
            id="username"
            placeholder="Enter your username"
            required
            class="form-input block w-full rounded border border-gray-300 px-3 py-2"
          />
        </div>

        <!-- Standard HTML input for password -->
        <div class="mb-6">
          <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
          <input 
            v-model="password"
            type="password" 
            id="password"
            placeholder="Enter your password"
            required
            class="form-input block w-full rounded border border-gray-300 px-3 py-2"
          />
        </div>

        <!-- Standard submit button -->
        <button type="submit" class="px-4 py-2 bg-blue-500 text-white rounded w-full" :disabled="loading">
          Login
        </button>
        <p v-if="errorMessage" class="text-red-500 mt-4 text-center">{{ errorMessage }}</p>
      </form>
    </div>
  </div>
</template>

<script>
import { useAuth } from '@/composables/useAuth'; // Your authentication composable

export default {
  name: "Login",
  data() {
    return {
      username: '', // v-model for username input
      password: '', // v-model for password input
      errorMessage: null,  // To handle error messages
    };
  },
  computed: {
    ...useAuth(),
  },
  methods: {
    async handleLogin() {
      const { login, error, success } = useAuth();  // Destructure state

      this.errorMessage = null;  // Clear error before login attempt

      await login(this.username, this.password);

      if (success.value) {
        // Redirect on successful login
        this.$router.push('/profile');
      } else {
        // Set error message if login fails
        this.errorMessage = error.value;
      }
    },
  },
};
</script>
