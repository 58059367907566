<template>
  <div class="chart-container">
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
import StockModule from 'highcharts/modules/stock';

StockModule(Highcharts);

export default defineComponent({
  name: 'CandlestickChart',
  components: {
    highcharts: HighchartsVue.component,
  },
  setup() {
    const chartOptions = ref({
      chart: {
        type: 'candlestick',
        height: '100%',
      },
      title: {
        text: 'Candlestick Chart Example',
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: {
        title: {
          text: 'Price',
        },
      },
      series: [
        {
          type: 'candlestick',
          name: 'AAPL Stock Price',
          data: [
            // Sample data in the format [timestamp, open, high, low, close]
            [1628121600000, 145.3, 148.0, 144.5, 147.6],
            [1628208000000, 147.6, 149.2, 146.8, 148.5],
            [1628294400000, 148.5, 150.0, 147.0, 149.0],
            [1628380800000, 149.0, 151.5, 148.8, 150.7],
            [1628467200000, 150.7, 152.3, 149.9, 151.1],
            // Add more data points as needed
          ],
          color: '#FF0000', // Falling candlestick color
          upColor: '#008000', // Rising candlestick color
        },
      ],
    });

    return {
      chartOptions,
    };
  },
});
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
}
</style>
