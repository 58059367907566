import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../pages/HomeView.vue';
import BlogView from '../pages/BlogView.vue';
import AddBlogPost from '@/pages/AddBlogPostView.vue';
import AboutView from '../pages/AboutView.vue';
import ComponentDemolitionView from '../pages/ComponentDemolitionView.vue';
import ThemeDemo from '../pages/ThemeDemo.vue'; // Import the new demo page
import DashboardView from '@/pages/DashboardView.vue';
import Login from '@/components/auth/Login.vue'; // Import the login component
import Register from '@/components/auth/Register.vue';
import Profile from '@/pages/ProfileView.vue';
import store from '@/store'; 
import ProductView from '@/pages/ProductView.vue'; 
import ShopView from '@/pages/ShopView.vue';
import SaySomethingNiceView from '@/pages/SaySomethingNiceView.vue';
import DemoViewMKII from '@/pages/DemoViewMKII.vue';


const routes = [
    { path: '/', component: HomeView },
    { path: '/about', component: AboutView },
    { path: '/saysomethingnice', component: SaySomethingNiceView },
    

];
  if (process.env.VUE_APP_SHOW_BLOG_POST_ROUTES === 'true') {
    routes.push(
      
      { path: '/blog', component: BlogView }, // Updated names
    )
}
  if (process.env.VUE_SHOW_SHOP_ROUTES === 'true') {
    routes.push(
      
    { path: '/product/:id', component: ProductView }, 
   { path: '/shop', component: ShopView },    )
  }
  if (process.env.VUE_APP_SHOW_DASHBOARD_ROUTES === 'true') {
    routes.push(
      
      { path: '/dashboard', component: DashboardView },
    )
  }
  if (process.env.VUE_APP_SHOW_DEMO_ROUTES === 'true') {
    routes.push(
      { path: '/demo', component: ComponentDemolitionView }, // New route
      { path: '/theme-demo', component: ThemeDemo }, // New route
      { path: '/demo-mkii', component: DemoViewMKII }, // New route
    )
  }
  if (process.env.VUE_APP_SHOW_AUTH_ROUTES === 'true') {
  
    routes.push(
      { path: '/login', name: 'LoginPage', component: Login },
      { path: '/register', name: 'registerPage', component: Register },
          
      { path: '/profile', component: Profile }
    );
  }
  if (process.env.VUE_APP_SHOW_BLOG_ROUTES === 'true') {
  
    routes.push(
      {
        path: '/add-blog-post',
        component: AddBlogPost,
      }
    );
  }


const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isLoggedIn;
  if (to.path === '/login' && isAuthenticated) {
    next('/profile'); // Prevent redirecting back to login if already logged in
  } else if (to.matched.some((record) => record.meta.requiresAuth) && !isAuthenticated) {
    next('/login'); // Redirect to login if not authenticated
  } else {
    next(); // Proceed to the route
  }
});


export default router;
