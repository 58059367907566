<template>
  <div class="dashboard-view">
    <h1>Data Visualization Dashboard</h1>

    <!-- File Upload Section -->
    <FileUpload @file-uploaded="handleFileUploaded" />

    <!-- Display the list of uploaded files -->
    <FileList @file-selected="handleFileSelected" />

    <!-- Chart Selector -->
    <div v-if="chartData" class="chart-selector">
      <label for="chartType">Select Chart Type:</label>
      <select v-model="selectedChartType" @change="changeChartType">
        <option value="area">Area Chart</option>
        <option value="bar">Bar Chart</option>
        <option value="box">Box Plot</option>
        <option value="bubble">Bubble Chart</option>
        <option value="bubblemap">Bubble Map</option>
        <option value="bullet">Bullet Chart</option>
        <option value="candlestick">Candlestick Chart</option>
        <option value="doughnut">Doughnut Chart</option>
        <option value="funnel">Funnel Chart</option>
        <option value="gauge">Gauge Chart</option>
        <option value="heatmap">Heatmap Chart</option>
        <option value="histogram">Histogram Chart</option>
        <option value="line">Line Chart</option>
        <option value="pie">Pie Chart</option>
        <option value="polararea">Polar Area Chart</option>
        <option value="radar">Radar Chart</option>
        <option value="sankey">Sankey Chart</option>
        <option value="scatter">Scatter Plot</option>
        <option value="stackedbar">Stacked Bar Chart</option>
        <option value="stepline">Step Line Chart</option>
        <option value="treemap">Treemap Chart</option>
        <option value="violin">Violin Plot</option>
        <option value="waterfall">Waterfall Chart</option>
      </select>
    </div>

    <!-- Dynamic Chart Rendering Based on Selection -->
    <div class="chart-container">
      <AreaChart v-if="selectedChartType === 'area'" :chartData="chartData" />
      <BarChart v-if="selectedChartType === 'bar'" :chartData="chartData" />
      <BoxPlot v-if="selectedChartType === 'box'" :chartData="chartData" />
      <BubbleChart v-if="selectedChartType === 'bubble'" :chartData="chartData" />
      <BubbleMap v-if="selectedChartType === 'bubblemap'" :chartData="chartData" />
      <BulletChart v-if="selectedChartType === 'bullet'" :chartData="chartData" />
      <CandlestickChart v-if="selectedChartType === 'candlestick'" :chartData="chartData" />
      <DoughnutChart v-if="selectedChartType === 'doughnut'" :chartData="chartData" />
      <FunnelChart v-if="selectedChartType === 'funnel'" :chartData="chartData" />
      <GaugeChart v-if="selectedChartType === 'gauge'" :chartData="chartData" />
      <HeatmapChart v-if="selectedChartType === 'heatmap'" :chartData="chartData" />
      <HistogramChart v-if="selectedChartType === 'histogram'" :chartData="chartData" />
      <LineChart v-if="selectedChartType === 'line'" :chartData="chartData" />
      <PieChart v-if="selectedChartType === 'pie'" :chartData="chartData" />
      <PolarAreaChart v-if="selectedChartType === 'polararea'" :chartData="chartData" />
      <RadarChart v-if="selectedChartType === 'radar'" :chartData="chartData" />
      <SankeyChart v-if="selectedChartType === 'sankey'" :chartData="chartData" />
      <ScatterPlot v-if="selectedChartType === 'scatter'" :chartData="chartData" />
      <StackedBarChart v-if="selectedChartType === 'stackedbar'" :chartData="chartData" />
      <StepLineChart v-if="selectedChartType === 'stepline'" :chartData="chartData" />
      <TreemapChart v-if="selectedChartType === 'treemap'" :chartData="chartData" />
      <ViolinPlot v-if="selectedChartType === 'violin'" :chartData="chartData" />
      <WaterfallChart v-if="selectedChartType === 'waterfall'" :chartData="chartData" />
    </div>
  </div>
</template>

<script>
import FileUpload from '@/components/file-management/FileUpload.vue';
import FileList from '@/components/file-management/FileList.vue';
import AreaChart from '@/components/charts/AreaChart.vue';
import BarChart from '@/components/charts/BarChart.vue';
import BoxPlot from '@/components/charts/BoxPlot.vue';
import BubbleChart from '@/components/charts/BubbleChart.vue';
import BubbleMap from '@/components/charts/BubbleMap.vue';
import BulletChart from '@/components/charts/BulletChart.vue';
import CandlestickChart from '@/components/charts/CandlestickChart.vue';
import DoughnutChart from '@/components/charts/DoughnutChart.vue';
import FunnelChart from '@/components/charts/FunnelChart.vue';
import GaugeChart from '@/components/charts/GaugeChart.vue';
import HeatmapChart from '@/components/charts/HeatmapChart.vue';
import HistogramChart from '@/components/charts/HistogramChart.vue';
import LineChart from '@/components/charts/LineChart.vue';
import PieChart from '@/components/charts/PieChart.vue';
import PolarAreaChart from '@/components/charts/PolarAreaChart.vue';
import RadarChart from '@/components/charts/RadarChart.vue';
import SankeyChart from '@/components/charts/SankeyChart.vue';
import ScatterPlot from '@/components/charts/ScatterPlot.vue';
import StackedBarChart from '@/components/charts/StackedBarChart.vue';
import StepLineChart from '@/components/charts/StepLineChart.vue';
import TreemapChart from '@/components/charts/TreemapChart.vue';
import ViolinPlot from '@/components/charts/ViolinPlot.vue';
import WaterfallChart from '@/components/charts/WaterfallChart.vue';
import { fetchProcessedData } from '@/services/api';

export default {
  components: {
    FileUpload,
    FileList,
    AreaChart,
    BarChart,
    BoxPlot,
    BubbleChart,
    BubbleMap,
    BulletChart,
    CandlestickChart,
    DoughnutChart,
    FunnelChart,
    GaugeChart,
    HeatmapChart,
    HistogramChart,
    LineChart,
    PieChart,
    PolarAreaChart,
    RadarChart,
    SankeyChart,
    ScatterPlot,
    StackedBarChart,
    StepLineChart,
    TreemapChart,
    ViolinPlot,
    WaterfallChart,
  },
  data() {
    return {
      chartData: null,           // Holds the data to be visualized
      selectedChartType: 'bar',  // Default chart type
    };
  },
  methods: {
    // Handle file upload completion
    handleFileUploaded(fileId) {
      this.loadChartData(fileId);
    },
    // Handle file selection from the file list
    handleFileSelected(fileId) {
      this.loadChartData(fileId);
    },
    // Load and process chart data
    loadChartData(fileId) {
      fetchProcessedData(fileId)
        .then(response => {
          this.chartData = response.data.data; // Set the chart data
        })
        .catch(error => {
          console.error('Error loading chart data:', error);
        });
    },
    // Handle chart type change
    changeChartType() {
      console.log('Chart type changed to:', this.selectedChartType);
    },
  },
};
</script>

<style scoped>
.dashboard-view {
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}
.chart-selector {
  margin: 20px 0;
}
.chart-container {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
}
</style>
