// src/composables/useAuth.js
import { ref } from 'vue';
import { loginUser, registerUser, logoutUser } from '@/services/api';

export function useAuth() {
  const loading = ref(false);
  const error = ref(null);
  const success = ref(false);
  const token = ref(sessionStorage.getItem('token')); // Use sessionStorage instead

  // Function to handle login
  const login = async (username, password) => {
    error.value = null;
    success.value = false;
    loading.value = true;

    try {
      const response = await loginUser({ username, password });

      token.value = response.data.token;
      sessionStorage.setItem('token', token.value); // Store in sessionStorage instead of localStorage
      success.value = true;
    } catch (err) {
      error.value = err.response?.data?.message || 'Login failed. Please try again.';
    } finally {
      loading.value = false;
    }
  };

  // Function to handle registration
  const register = async (username, password) => {
    error.value = null;
    success.value = false;
    loading.value = true;

    try {
      await registerUser({ username, password });
      success.value = true;
    } catch (err) {
      error.value = err.response?.data?.message || 'Registration failed. Please try again.';
    } finally {
      loading.value = false;
    }
  };

  // Function to handle logout
  const logout = () => {
    logoutUser(); // Call API logout endpoint
    token.value = null;
    sessionStorage.removeItem('token'); // Clear session storage token
  };

  return {
    login,
    register,
    logout,
    loading,
    error,
    success,
    token,
  };
}
