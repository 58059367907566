<template>
  <div class="image-texture" :style="textureStyle"></div>
</template>

<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  opacity: {
    type: Number,
    default: 0.3,
  },
  mode: {
    type: String,
    default: 'cover', // could be 'cover', 'contain', or 'repeat'
  },
});

const textureStyle = computed(() => ({
  backgroundImage: `url(${props.src})`,
  backgroundSize: props.mode,
  backgroundRepeat: props.mode === 'repeat' ? 'repeat' : 'no-repeat',
  backgroundPosition: 'center',
  opacity: props.opacity,
}));
</script>

<style scoped>
.image-texture {
  position: absolute;
  inset: 0;
  z-index: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}
</style>
