<template>
  <div class="p-6 bg-gray-100 min-h-screen">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      <div v-for="i in 8" :key="i" class="relative overflow-hidden group">
        <img :src="`https://picsum.photos/400/300?random=${i}`" class="w-full h-full object-cover transition-transform transform group-hover:scale-110" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResponsiveImageGallery',
};
</script>
