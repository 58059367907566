<template>
  <div class="p-8 space-y-12 bg-black text-white min-h-screen">
    <h1 class="text-4xl font-bold">Visual Effect Demo MkII</h1>

    <!-- Single Effects -->
    <div v-for="effect in singleEffects" :key="effect" class="space-y-4">
      <h2 class="text-2xl font-semibold capitalize">{{ effect }}</h2>
      <VisualEffectWrapper :effects="effect">
        <BaseImage :src="logo" alt="Logo" class="max-w-xs mx-auto" />
      </VisualEffectWrapper>
    </div>

    <!-- Stacked Combinations -->
    <div class="space-y-4">
      <h2 class="text-2xl font-semibold">Stacked: CRT + Glow</h2>
      <VisualEffectWrapper :effects="['crtFlicker', 'glowPulse']">
        <BaseImage :src="logo" alt="Logo" class="max-w-xs mx-auto" />
      </VisualEffectWrapper>

      <h2 class="text-2xl font-semibold">Stacked: Plasma + Vortex</h2>
      <VisualEffectWrapper :effects="['plasmaBloom', 'vortexRipple']">
        <BaseImage :src="logo" alt="Logo" class="max-w-xs mx-auto" />
      </VisualEffectWrapper>

      <h2 class="text-2xl font-semibold">Stacked: Neural + Digital + Bloom</h2>
      <VisualEffectWrapper :effects="['neuralPulse', 'digitalNoiseFade', 'bloomFade']">
        <BaseImage :src="logo" alt="Logo" class="max-w-xs mx-auto" />
      </VisualEffectWrapper>
    </div>
  </div>
</template>

<script setup>
import VisualEffectWrapper from '@/components/effects/VisualEffectWrapper.vue';
import BaseImage from '@/components/common/BaseImage.vue';
import logo from '@/assets/images/logo_mk_ii.png'; // or .jpg if it's a .jpg

const singleEffects = [
  'bubblingInvertPush',
  'liquidSmear',
  'mirageDisplace',
  'crtFlicker',
  'glowPulse',
  'shatterBreak',
  'neuralPulse',
  'plasmaBloom',
  'vortexRipple',
  'digitalNoiseFade',
  'bloomFade',
];
</script>

<style scoped>
h2 {
  margin-top: 2rem;
}
</style>
