<template>
  <div class="min-h-screen overflow-hidden">
    <div class="parallax-bg h-screen w-full bg-fixed bg-cover bg-center" style="background-image: url('https://picsum.photos/1600/900');"></div>
    <div class="bg-white p-10 text-center">
      <h1 class="text-3xl font-bold">Parallax Effect</h1>
      <p class="mt-4 text-gray-600">Scroll down to see the parallax effect in action.</p>
    </div>
    <div class="parallax-bg h-screen w-full bg-fixed bg-cover bg-center" style="background-image: url('https://picsum.photos/1600/900?2');"></div>
  </div>
</template>

<script>
export default {
  name: 'ParallaxEffect',
};
</script>

<style scoped>
.parallax-bg {
  background-attachment: fixed;
}
</style>
