<template>
  <div class="chart-container">
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
import SolidGauge from 'highcharts/modules/solid-gauge';
import HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore(Highcharts);
SolidGauge(Highcharts);

export default defineComponent({
  name: 'GaugeChart',
  components: {
    highcharts: HighchartsVue.component,
  },
  setup() {
    const chartOptions = ref({
      chart: {
        type: 'solidgauge',
        height: '100%',
      },
      title: {
        text: 'Gauge Chart Example',
      },
      pane: {
        center: ['50%', '85%'],
        size: '100%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc',
        },
      },
      yAxis: {
        min: 0,
        max: 200,
        title: {
          text: 'Speed',
        },
        stops: [
          [0.1, '#55BF3B'], // green
          [0.5, '#DDDF0D'], // yellow
          [0.9, '#DF5353'], // red
        ],
        lineWidth: 0,
        tickPositions: [],
      },
      series: [
        {
          name: 'Speed',
          data: [80],
          tooltip: {
            valueSuffix: ' km/h',
          },
          dataLabels: {
            format: '<div style="text-align:center"><span style="font-size:25px">{y}</span><br/>' +
                    '<span style="font-size:12px;opacity:0.4">km/h</span></div>',
          },
        },
      ],
    });

    return {
      chartOptions,
    };
  },
});
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
}
</style>
