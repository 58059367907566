export default function neuralPulse(el, _options = {}) {
  const canvas = el.tagName === 'CANVAS' ? el : document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (el.tagName !== 'CANVAS') {
    const { width, height } = el.getBoundingClientRect();
    canvas.width = width;
    canvas.height = height;

    el.style.position = 'relative';
    canvas.style.position = 'absolute';
    canvas.style.top = 0;
    canvas.style.left = 0;
    canvas.style.pointerEvents = 'none';

    el.appendChild(canvas);
  }

  const nodes = Array.from({ length: 30 }, () => ({
    x: Math.random() * canvas.width,
    y: Math.random() * canvas.height,
    r: 2 + Math.random() * 2,
    pulseOffset: Math.random() * 100,
  }));

  function drawLines() {
    for (let i = 0; i < nodes.length; i++) {
      for (let j = i + 1; j < nodes.length; j++) {
        const dx = nodes[i].x - nodes[j].x;
        const dy = nodes[i].y - nodes[j].y;
        const dist = Math.sqrt(dx * dx + dy * dy);
        if (dist < 100) {
          ctx.strokeStyle = `rgba(0,255,255,${1 - dist / 100})`;
          ctx.lineWidth = 0.5;
          ctx.beginPath();
          ctx.moveTo(nodes[i].x, nodes[i].y);
          ctx.lineTo(nodes[j].x, nodes[j].y);
          ctx.stroke();
        }
      }
    }
  }

  let time = 0;

  function draw() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw lines first
    drawLines();

    // Draw pulsing nodes
    nodes.forEach((node) => {
      const pulse = 0.5 + Math.sin((time + node.pulseOffset) * 0.05) * 0.5;
      ctx.beginPath();
      ctx.arc(node.x, node.y, node.r + pulse * 2, 0, Math.PI * 2);
      ctx.fillStyle = 'rgba(0,255,255,0.3)';
      ctx.fill();
    });

    time++;
    requestAnimationFrame(draw);
  }

  draw();
}
