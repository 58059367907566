<template>
  <div class="textured-background">
    <component
      :is="backgroundTexture.component"
      v-if="backgroundTexture.component"
      v-bind="backgroundTexture.props"
      class="texture-layer"
    />
    <div class="content-layer">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useTheme } from '@/composables/useTheme';

const { currentTextures } = useTheme();

const backgroundTexture = computed(() => currentTextures.value.background);
</script>

<style scoped>
.textured-background {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.texture-layer {
  position: absolute;
  inset: 0;
  z-index: 0;
  pointer-events: none;
}

.content-layer {
  position: relative;
  z-index: 1;
}
</style>
