export default function shatterBreak(el, _options = {}) {
  const canvas = el.tagName === 'CANVAS' ? el : document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (el.tagName !== 'CANVAS') {
    const { width, height } = el.getBoundingClientRect();
    canvas.width = width;
    canvas.height = height;

    el.style.position = 'relative';
    canvas.style.position = 'absolute';
    canvas.style.top = 0;
    canvas.style.left = 0;
    canvas.style.pointerEvents = 'none';

    el.appendChild(canvas);
  }

  // Generate random shards
  const shards = Array.from({ length: 20 }, () => ({
    x: Math.random() * canvas.width,
    y: Math.random() * canvas.height,
    vx: (Math.random() - 0.5) * 4,
    vy: (Math.random() - 0.5) * 4,
    size: 10 + Math.random() * 20,
    angle: Math.random() * Math.PI * 2,
  }));

  function draw() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    shards.forEach((shard) => {
      shard.x += shard.vx;
      shard.y += shard.vy;
      shard.angle += 0.02;

      ctx.save();
      ctx.translate(shard.x, shard.y);
      ctx.rotate(shard.angle);
      ctx.fillStyle = 'rgba(255,255,255,0.05)';
      ctx.beginPath();
      ctx.moveTo(0, -shard.size / 2);
      ctx.lineTo(shard.size / 2, 0);
      ctx.lineTo(0, shard.size / 2);
      ctx.lineTo(-shard.size / 2, 0);
      ctx.closePath();
      ctx.fill();
      ctx.restore();
    });

    requestAnimationFrame(draw);
  }

  draw();
}
