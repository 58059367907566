<template>
  <div class="container mx-auto py-8">
    <h1 class="text-3xl font-bold mb-6">Shop Our Collection</h1>

    <!-- Filter and Sorting Section -->
    <div class="flex justify-between items-center mb-4">
      <!-- Category Filter -->
      <div>
        <label class="font-medium">Filter by Category:</label>
        <select v-model="selectedCategory" @change="filterByCategory" class="ml-2 p-2 border rounded">
          <option value="">All</option>
          <option v-for="category in categories" :key="category" :value="category">{{ category }}</option>
        </select>
      </div>

      <!-- Sorting Options -->
      <div>
        <label class="font-medium">Sort by:</label>
        <select v-model="sortOption" @change="sortProducts" class="ml-2 p-2 border rounded">
          <option value="name">Name</option>
          <option value="priceAsc">Price: Low to High</option>
          <option value="priceDesc">Price: High to Low</option>
        </select>
      </div>
    </div>

    <!-- Product Grid -->
    <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div v-for="product in paginatedProducts" :key="product.id" class="p-4 border rounded-lg">
        <router-link :to="'/product/' + product.id">
          <img :src="product.images[0]" alt="Product Image" class="w-full h-48 object-cover mb-4 rounded-lg" />
          <h2 class="text-lg font-semibold">{{ product.name }}</h2>
          <p class="text-gray-600">${{ product.price }}</p>
        </router-link>
      </div>
    </div>

    <!-- Pagination -->
    <div class="flex justify-center mt-8">
      <button 
        @click="prevPage" 
        :disabled="currentPage === 1" 
        class="px-4 py-2 border bg-gray-200 rounded-lg mx-2"
      >
        Previous
      </button>
      <span class="font-medium">{{ currentPage }}</span>
      <button 
        @click="nextPage" 
        :disabled="currentPage === totalPages" 
        class="px-4 py-2 border bg-gray-200 rounded-lg mx-2"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
import products from "@/assets/products.json"; // Assume this is where your product data is stored

export default {
  data() {
    return {
      products, // Load all products
      filteredProducts: products,
      currentPage: 1,
      productsPerPage: 6,
      selectedCategory: '',
      sortOption: 'name',
      categories: ['Men', 'Women', 'Accessories'], // Example categories
    };
  },
  computed: {
    // Paginate the filtered and sorted products
    paginatedProducts() {
      const start = (this.currentPage - 1) * this.productsPerPage;
      const end = start + this.productsPerPage;
      return this.filteredProducts.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredProducts.length / this.productsPerPage);
    }
  },
  methods: {
    // Filter products by category
    filterByCategory() {
      if (this.selectedCategory === '') {
        this.filteredProducts = this.products;
      } else {
        this.filteredProducts = this.products.filter(product => product.category === this.selectedCategory);
      }
      this.currentPage = 1; // Reset to first page after filtering
    },
    // Sort products by selected option
    sortProducts() {
      if (this.sortOption === 'priceAsc') {
        this.filteredProducts.sort((a, b) => a.price - b.price);
      } else if (this.sortOption === 'priceDesc') {
        this.filteredProducts.sort((a, b) => b.price - a.price);
      } else {
        this.filteredProducts.sort((a, b) => a.name.localeCompare(b.name));
      }
    },
    // Handle pagination
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    }
  }
};
</script>

<style scoped>
/* Add any scoped styles for the shop view */
</style>
