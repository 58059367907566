<template>
  <div class="chart-container">
    <Line :chart-data="formattedChartData" :options="mergedOptions" />
  </div>
</template>

<script>
import { Line } from 'vue-chartjs';
import { defineComponent, computed } from 'vue';
import { merge } from 'lodash';

export default defineComponent({
  name: 'StepLineChart',
  components: {
    Line,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: String,
      default: 'light',
    },
  },
  setup(props) {
    const defaultOptions = computed(() => ({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            color: props.theme === 'dark' ? '#444' : '#ddd',
          },
          ticks: {
            color: props.theme === 'dark' ? '#fff' : '#000',
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            color: props.theme === 'dark' ? '#444' : '#ddd',
          },
          ticks: {
            color: props.theme === 'dark' ? '#fff' : '#000',
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          labels: {
            color: props.theme === 'dark' ? '#fff' : '#000',
          },
        },
        tooltip: {
          backgroundColor: props.theme === 'dark' ? '#333' : '#fff',
          titleColor: props.theme === 'dark' ? '#fff' : '#000',
          bodyColor: props.theme === 'dark' ? '#fff' : '#000',
        },
      },
    }));

    const mergedOptions = computed(() => merge(defaultOptions.value, props.options));

    const formattedChartData = computed(() => ({
      ...props.chartData,
      datasets: props.chartData.datasets.map((dataset) => ({
        ...dataset,
        borderColor: dataset.borderColor || '#42A5F5',
        backgroundColor: dataset.backgroundColor || 'rgba(66, 165, 245, 0.2)',
        borderWidth: 2,
        stepped: true, // Key option to create step lines
      })),
    }));

    return {
      mergedOptions,
      formattedChartData,
    };
  },
});
</script>

<style scoped>
.chart-container {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
