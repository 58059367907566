// File: client/src/utils/visual-effects/effectRegistry.js

import bubblingInvertPush from './effects/bubblingInvertPush.js';
import liquidSmear from './effects/liquidSmear.js';
import mirageDisplace from './effects/mirageDisplace.js';
import crtFlicker from './effects/crtFlicker.js';
import glowPulse from './effects/glowPulse.js';
import shatterBreak from './effects/shatterBreak.js';
import neuralPulse from './effects/neuralPulse.js';
import plasmaBloom from './effects/plasmaBloom.js';
import vortexRipple from './effects/vortexRipple.js';
import digitalNoiseFade from './effects/digitalNoiseFade.js';
import bloomFade from './effects/bloomFade.js';

const effectRegistry = {
  bubblingInvertPush,
  liquidSmear,
  mirageDisplace,
  crtFlicker,
  glowPulse,
  shatterBreak,
  neuralPulse,
  plasmaBloom,
  vortexRipple,
  digitalNoiseFade,
  bloomFade,
};

export default effectRegistry;
