// store/modules/auth.js
import { loginUser, registerUser, logoutUser } from '@/services/api';

const state = {
  token: localStorage.getItem('token') || '',
  user: null,
  status: '',
};

const mutations = {
  auth_request(state) {
    state.status = 'loading';
  },
  auth_success(state, { token, user }) {
    console.log('auth_success committed with token:', token);
    state.status = 'success';
    state.token = token;
    state.user = user;
  },
  auth_error(state) {
    state.status = 'error';
  },
  logout(state) {
    state.status = '';
    state.token = '';
    state.user = null;
  },
  set_user(state, user) {
    state.user = user;
  },
};

const actions = {
  async login({ commit }, user) {
    commit('auth_request');
    try {
      const response = await loginUser(user);
      const token = response.data.token;
      const userData = response.data.user;
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(userData));
      commit('auth_success', { token, user: userData });
    } catch (error) {
      commit('auth_error');
      localStorage.removeItem('token');
      throw error;
    }
  },
  async register({ commit }, user) {
    commit('auth_request');
    try {
      await registerUser(user);
    } catch (error) {
      commit('auth_error');
      throw error;
    }
  },
  logout({ commit }) {
    commit('logout');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    logoutUser();
  },
  checkAuthState({ commit }) {
    const token = localStorage.getItem('token');
    let user = null;

    try {
      const userData = localStorage.getItem('user');
      if (userData) {
        user = JSON.parse(userData);
      }
    } catch (error) {
      console.error("Error parsing user from localStorage:", error);
      localStorage.removeItem('user');
    }

    if (token && user) {
      commit('auth_success', { token, user });
    } else {
      commit('logout');
    }
  },
};

const getters = {
  isLoggedIn: (state) => !!state.token,
  authStatus: (state) => state.status,
  currentUser: (state) => state.user,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
