<template>
  <div class="chart-container">
    <Bubble :chart-data="formattedChartData" :options="mergedOptions" />
  </div>
</template>

<script>
import { Bubble } from 'vue-chartjs';
import { defineComponent, computed } from 'vue';
import { merge } from 'lodash';

export default defineComponent({
  name: 'BubbleChart',
  components: {
    Bubble,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: String,
      default: 'light',
    },
  },
  setup(props) {
    const defaultOptions = computed(() => ({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            color: props.theme === 'dark' ? '#444' : '#ddd',
          },
          ticks: {
            color: props.theme === 'dark' ? '#fff' : '#000',
          },
        },
        y: {
          grid: {
            color: props.theme === 'dark' ? '#444' : '#ddd',
          },
          ticks: {
            color: props.theme === 'dark' ? '#fff' : '#000',
          },
        },
      },
      plugins: {
        legend: {
          labels: {
            color: props.theme === 'dark' ? '#fff' : '#000',
          },
        },
        tooltip: {
          backgroundColor: props.theme === 'dark' ? '#333' : '#fff',
          titleColor: props.theme === 'dark' ? '#fff' : '#000',
          bodyColor: props.theme === 'dark' ? '#fff' : '#000',
        },
      },
    }));

    const mergedOptions = computed(() => merge(defaultOptions.value, props.options));

    const formattedChartData = computed(() => ({
      ...props.chartData,
      datasets: props.chartData.datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: dataset.backgroundColor || 'rgba(75, 192, 192, 0.4)',
        borderColor: dataset.borderColor || 'rgba(75, 192, 192, 1)',
        borderWidth: dataset.borderWidth || 1,
        hoverBackgroundColor: dataset.hoverBackgroundColor || 'rgba(75, 192, 192, 0.6)',
        hoverBorderColor: dataset.hoverBorderColor || 'rgba(75, 192, 192, 1)',
      })),
    }));

    return {
      mergedOptions,
      formattedChartData,
    };
  },
});
</script>

<style scoped>
.chart-container {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
