<template>
  <div class="file-upload">
    <h2>Upload a CSV File</h2>
    <div
      class="drop-zone"
      @dragover.prevent
      @dragenter.prevent="dragging = true"
      @drop.prevent="handleDrop"
      @dragleave="dragging = false"
      :class="{ dragging }"
    >
      <p v-if="!dragging">Drag and drop your files here or</p>
      <p v-else>Release to upload</p>
      <input type="file" @change="handleFileSelect" hidden ref="fileInput" />
      <button @click="triggerFileInput">Browse Files</button>
    </div>
    <FilePreview v-if="selectedFile" :file="selectedFile" />
    <div v-if="uploadError" class="error">{{ uploadError }}</div>
    <div v-if="uploadSuccess" class="success">File uploaded successfully!</div>
    <button v-if="selectedFile" @click="upload">Upload</button>
  </div>
</template>

<script>

import { uploadFile } from '@/services/api'; // Import the API function for uploading files
import FilePreview from '@/components/file-management/FilePreview.vue'; // Import FilePreview component

export default {
  components: {
    FilePreview,
  },
  data() {
    return {
      dragging: false,        // State to track drag-and-drop behavior
      selectedFile: null,     // Holds the file selected for upload and preview
      uploadError: null,      // Error message display
      uploadSuccess: false,   // Success state indicator
    };
  },
  methods: {
    // Trigger the hidden file input when clicking the 'Browse Files' button
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    // Handle file selection through the input field
    handleFileSelect(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file; // Set the selected file for preview
      }
    },
    // Handle files dropped into the drag-and-drop area
    handleDrop(event) {
      this.dragging = false;
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        this.selectedFile = files[0]; // Set the selected file for preview
      }
    },
    // Upload the selected file to the backend
    upload() {
      if (this.selectedFile) {
        this.uploadError = null;
        this.uploadSuccess = false;

        uploadFile(this.selectedFile)
          .then(response => {
            console.log('File uploaded successfully:', response.data);
            this.uploadSuccess = true; // Set success message
          })
          .catch(error => {
            console.error('Error uploading file:', error);
            this.uploadError = 'Failed to upload file. Please try again.'; // Set error message
          });
      } else {
        this.uploadError = 'Please select a file to upload.'; // Show error if no file selected
      }
    },
  },
};
</script>

<style scoped>
.file-upload {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  margin: 20px auto;
  text-align: center;
}
.drop-zone {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.drop-zone.dragging {
  background-color: #e0e0e0;
}
.error {
  color: red;
  margin-top: 10px;
}
.success {
  color: green;
  margin-top: 10px;
}
button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
}
button:hover {
  background-color: #45a049;
}
</style>
