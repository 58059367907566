<template>
  <canvas ref="canvasRef" class="w-full h-full block" />
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
});

const canvasRef = ref(null);
let animationFrameId = null;

onMounted(() => {
  const canvas = canvasRef.value;
  const ctx = canvas.getContext('2d');
const img = new Image();
img.crossOrigin = 'Anonymous'; // prevent tainted canvas if needed

if (typeof props.src === 'string') {
  img.src = props.src;
} else if (props.src instanceof Object && props.src.default) {
  img.src = props.src.default;
} else {
  console.warn('Invalid image source passed to RGBGhostEffect:', props.src);
}


  img.onload = () => {
    const w = img.width;
    const h = img.height;
    canvas.width = w;
    canvas.height = h;

    const buffer = document.createElement('canvas');
    buffer.width = w;
    buffer.height = h;
    const bufferCtx = buffer.getContext('2d');

    let time = 0;

    const draw = () => {
      bufferCtx.drawImage(img, 0, 0, w, h);
      const src = bufferCtx.getImageData(0, 0, w, h);
      const dst = ctx.createImageData(w, h);

      for (let y = 0; y < h; y++) {
        const offsetR = Math.sin((y + time) * 0.1) * 5;
        const offsetG = Math.sin((y + time) * 0.12 + 10) * 5;
        const offsetB = Math.sin((y + time) * 0.14 + 20) * 5;

        for (let x = 0; x < w; x++) {
          const i = (y * w + x) * 4;

          const rIdx = ((y * w + ((x + offsetR + w) % w | 0)) * 4);
          const gIdx = ((y * w + ((x + offsetG + w) % w | 0)) * 4);
          const bIdx = ((y * w + ((x + offsetB + w) % w | 0)) * 4);

          dst.data[i + 0] = src.data[rIdx + 0];
          dst.data[i + 1] = src.data[gIdx + 1];
          dst.data[i + 2] = src.data[bIdx + 2];
          dst.data[i + 3] = src.data[i + 3];
            //dst.data[i + 3] = 255;
          //dst.data[i + 3] = src.data[si + 3]; // copy alpha from source

        }
      }

      ctx.putImageData(dst, 0, 0);
      time++;
      animationFrameId = requestAnimationFrame(draw);
    };

    draw();
  };
});
</script>

<style scoped>
canvas {
  image-rendering: pixelated;
}
</style>
