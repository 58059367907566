<template>
  <div>
    <!-- Div container for the ECharts instance -->
    <div ref="waterfallChart" style="width: 100%; height: 400px;"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'WaterfallChart',
  props: {
    categories: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: null, // Reference to the ECharts instance
    };
  },
  methods: {
    initChart() {
      const chartDom = this.$refs.waterfallChart;
      this.chart = echarts.init(chartDom);

      const option = {
        title: {
          text: 'Dynamic Waterfall Chart',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        xAxis: {
          type: 'category',
          data: this.categories, // Using categories from props
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            type: 'bar',
            stack: 'total',
            label: {
              show: true,
              position: 'inside',
            },
            data: this.data, // Using data from props
          },
        ],
      };

      this.chart.setOption(option);
    },
    updateChart(categories, data) {
      if (this.chart) {
        const option = {
          xAxis: {
            data: categories,
          },
          series: [
            {
              data: data,
            },
          ],
        };
        this.chart.setOption(option);
      }
    },
  },
  watch: {
    // Watch for changes in props to update the chart dynamically
    categories(newCategories) {
      this.updateChart(newCategories, this.data);
    },
    data(newData) {
      this.updateChart(this.categories, newData);
    },
  },
  mounted() {
    this.initChart(); // Initialize the chart on component mount
  },
};
</script>

<style scoped>
.plot-container {
  width: 100%;
  height: 400px;
}
</style>
