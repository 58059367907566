<template>
  <TexturedBackground>
    <div class="app-layout">
      
      <!-- Main Navbar at the top -->
      <MainNavbar />

      <div class="main-content">
        <!-- Left Sidebar --> 
        <!-- leaving sidebars out for now -->
      <!-- <SidebarLeft /> --> 

        <!-- Router View: Main dynamic content area -->
        <div class="view-container">
          <router-view />
        </div>

        <!-- Right Sidebar -->
        <!-- <SidebarRight /> -->
      </div>
    
      <!-- Footer at the bottom -->
      <Footer />
      
    </div>
  </TexturedBackground>
</template>


<script setup>
import TexturedBackground from '../effects/TexturedBackground.vue'; 
</script>
<script>
//import SidebarLeft from './SidebarLeft.vue';
//import SidebarRight from './SidebarRight.vue';
//import TexturedBackground from '../effects/TexturedBackground.vue';
import Footer from './Footer.vue';
import MainNavbar from './MainNavbar.vue';
import { mapActions } from 'vuex';

export default {
  name: "AppLayout",
  components: {
   // SidebarLeft,
   // SidebarRight,
    Footer,
    MainNavbar,
  },
  created() {
    console.log("[AppLayout] AppLayout component created. Initiating app layout.");
    this.checkAuthState()
      .then(() => console.log("[AppLayout] Auth state checked successfully."))
      .catch((err) => console.error("[AppLayout] Error checking auth state:", err));
  },
  methods: {
    ...mapActions(['checkAuthState']),
  },
};
</script>

<style scoped>
/* App layout styles */
.app-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
}

/* Main content area with sidebars and view */
.main-content {
  display: flex;
  flex: 1;
  padding-top: 60px; /* Adjusting for fixed navbar */
  overflow: hidden;
}

.view-container {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
}

/* Responsive design for small screens */
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
  }
  
  .view-container {
    padding: 10px;
  }
}
</style>
