export default function mirageDisplace(el, _options = {}) {
  const canvas = el.tagName === 'CANVAS' ? el : document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (el.tagName !== 'CANVAS') {
    const { width, height } = el.getBoundingClientRect();
    canvas.width = width;
    canvas.height = height;

    el.style.position = 'relative';
    canvas.style.position = 'absolute';
    canvas.style.top = 0;
    canvas.style.left = 0;
    canvas.style.pointerEvents = 'none';

    el.appendChild(canvas);
  }

  let time = 0;

  function draw() {
    const w = canvas.width;
    const h = canvas.height;

    ctx.clearRect(0, 0, w, h);

    const stripeHeight = 4;
    for (let y = 0; y < h; y += stripeHeight) {
      const offset = Math.sin(time * 0.03 + y * 0.1) * 5;
      ctx.fillStyle = `rgba(255, 255, 255, 0.06)`;
      ctx.fillRect(offset, y, w, stripeHeight);
    }

    time++;
    requestAnimationFrame(draw);
  }

  draw();
}
