<template>
  <div class="chart-container">
    <Radar :chart-data="formattedChartData" :options="mergedOptions" />
  </div>
</template>

<script>
import { Radar } from 'vue-chartjs';
import { defineComponent, computed } from 'vue';
import { merge } from 'lodash';

export default defineComponent({
  name: 'RadarChart',
  components: {
    Radar,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: String,
      default: 'light',
    },
    filled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const defaultOptions = computed(() => ({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        r: {
          grid: {
            color: props.theme === 'dark' ? '#444' : '#ddd',
          },
          ticks: {
            color: props.theme === 'dark' ? '#fff' : '#000',
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          labels: {
            color: props.theme === 'dark' ? '#fff' : '#000',
          },
        },
        tooltip: {
          backgroundColor: props.theme === 'dark' ? '#333' : '#fff',
          titleColor: props.theme === 'dark' ? '#fff' : '#000',
          bodyColor: props.theme === 'dark' ? '#fff' : '#000',
        },
      },
    }));

    const mergedOptions = computed(() => merge(defaultOptions.value, props.options));

    const formattedChartData = computed(() => ({
      ...props.chartData,
      datasets: props.chartData.datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: props.filled ? (dataset.backgroundColor || 'rgba(66, 165, 245, 0.2)') : 'rgba(0, 0, 0, 0)',
        borderColor: dataset.borderColor || '#1E88E5',
        borderWidth: 2,
        fill: props.filled ? true : false, // Fill the area under the line if 'filled' prop is true
      })),
    }));

    return {
      mergedOptions,
      formattedChartData,
    };
  },
});
</script>

<style scoped>
.chart-container {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
