export default function liquidSmear(el, _options = {}) {
  const canvas = el.tagName === 'CANVAS' ? el : document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (el.tagName !== 'CANVAS') {
    const { width, height } = el.getBoundingClientRect();
    canvas.width = width;
    canvas.height = height;

    el.style.position = 'relative';
    canvas.style.position = 'absolute';
    canvas.style.top = 0;
    canvas.style.left = 0;
    canvas.style.pointerEvents = 'none';

    el.appendChild(canvas);
  }

  let time = 0;

  function draw() {
    const w = canvas.width;
    const h = canvas.height;

    ctx.clearRect(0, 0, w, h);

    const smearWidth = 20;
    for (let i = 0; i < w; i += smearWidth) {
      const offset = Math.sin((time * 0.02) + i * 0.05) * 10;
      ctx.fillStyle = `hsl(${(i + time) % 360}, 100%, 60%)`;
      ctx.fillRect(i + offset, 0, smearWidth, h);
    }

    time++;
    requestAnimationFrame(draw);
  }

  draw();
}
