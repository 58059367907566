<template>
    <div class="about-view container mx-auto mt-10">
        <h1 class="text-4xl font-bold text-center">About Me</h1>
        <p class="text-center mt-4">My name is Ethan Ransberger, and I work in finance and enjoy software development. I also like to make music and "art". This is my website to showcase some of my projects, including the website itself. I can be reached at [REDACTED], GitHub, or linkedin.</p> 
        <p class="text-center mt-4">https://www.linkedin.com/in/ethanransberger/</p>
        <p class="text-center mt-4">https://github.com/EthanRansberger</p>
 
    </div>
</template>

<script>
export default {
    name: 'AboutView',
}
</script>

<style scoped>
.about-page {
    padding: 20px;
}
</style>
