<template>
  <div class="h-screen flex items-center justify-center bg-gray-100">
    <div class="tilt-card bg-white rounded-lg shadow-lg p-6 max-w-sm">
      <h2 class="text-xl font-bold">3D Tilt Card</h2>
      <p class="mt-4 text-gray-600">Hover over this card to see the 3D effect.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TiltCard',
};
</script>

<style scoped>
.tilt-card {
  transition: transform 0.2s;
}
.tilt-card:hover {
  transform: perspective(1000px) rotateX(10deg) rotateY(-10deg);
}
</style>
