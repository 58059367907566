<template>
  <div class="funnel-chart-container">
    <!-- The canvas element for the chart -->
    <canvas ref="funnelCanvas"></canvas>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import Chart from 'chart.js/auto'; // Import Chart.js
// Import funnel chart plugin if needed, e.g., ChartFunnel

export default {
  name: 'FunnelChart',
  setup() {
    // Create a ref to hold the canvas element
    const funnelCanvas = ref(null);
    let chartInstance = null; // Holds the chart instance for later cleanup

    // Initialize the chart when the component is mounted
    onMounted(() => {
      if (funnelCanvas.value) {
        chartInstance = new Chart(funnelCanvas.value, {
          type: 'bar', // Example: use 'funnel' if the funnel plugin is used
          data: {
            labels: ['Stage 1', 'Stage 2', 'Stage 3'], // Example labels
            datasets: [
              {
                label: 'Funnel Data',
                data: [100, 70, 50], // Example data
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'], // Example colors
              },
            ],
          },
          options: {
            // Example options
            plugins: {
              legend: {
                display: true,
              },
            },
          },
        });
      }
    });

    // Cleanup the chart instance when the component is unmounted
    onUnmounted(() => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    });

    return {
      funnelCanvas,
    };
  },
};
</script>

<style scoped>
.funnel-chart-container {
  max-width: 600px;
  margin: 0 auto;
}
</style>
