<template>
  <div>
    <div ref="violinPlot" class="plot-container"></div>
    <!-- File upload input -->
    <input type="file" @change="handleFileUpload" accept=".csv, .json" class="file-input" />
    <!-- Button to fetch data from the server or other sources -->
    <button @click="fetchDataFromServer" class="fetch-data-button">Fetch Data</button>
  </div>
</template>

<script>
import Plotly from 'plotly.js-dist';

export default {
  name: 'ViolinPlot',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      plotData: [],  // This will hold the processed data for the chart
    };
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0]; // Get the selected file
      if (file) {
        const reader = new FileReader(); // Create a new FileReader instance

        // Define the onload callback function to process the file
        reader.onload = (e) => {
          const contents = e.target.result;
          try {
            let parsedData;
            if (file.type === 'application/json') {
              parsedData = JSON.parse(contents); // Parse JSON file
            } else if (file.type === 'text/csv') {
              parsedData = this.parseCSV(contents); // Parse CSV file
            } else {
              console.error('Unsupported file type');
              return;
            }

            this.plotData = parsedData; // Set the parsed data to plotData
            this.plotViolinChart(); // Call method to plot the chart
          } catch (error) {
            console.error('Error parsing file:', error);
          }
        };

        reader.readAsText(file); // Read the file as text
      }
    },
    parseCSV(csvContent) {
      // Basic CSV parsing function (you can use a library like PapaParse for more complex needs)
      const lines = csvContent.split('\n');
      return lines.map(line => line.split(','));
    },
    plotViolinChart() {
      const plotElement = this.$refs.violinPlot;

      // Example setup for a basic Plotly violin plot
      const trace = {
        y: this.plotData,
        type: 'violin',
        points: 'all',
        jitter: 0.3,
        pointpos: -1.8,
        box: {
          visible: true
        },
        line: {
          color: 'blue'
        }
      };

      const layout = {
        title: 'Violin Plot',
        yaxis: {
          zeroline: false
        }
      };

      Plotly.newPlot(plotElement, [trace], layout);
    },
    fetchDataFromServer() {
      // Method for fetching data from a server or API - placeholder for future implementation
      console.log('Fetch data method to be implemented.');
    }
  },
  mounted() {
    this.plotViolinChart(); // Plot initial chart with default data
  }
};
</script>

<style scoped>
.plot-container {
  width: 100%;
  height: 400px;
}
.file-input {
  margin-top: 20px;
}
.fetch-data-button {
  margin-top: 10px;
}
</style>
